import React, {useState} from 'react';
import './myaccount.css';
import { useForm } from 'react-hook-form';
import {Redirect} from 'react-router-dom';
import * as firebase from "firebase/app";
import auth from "firebase/auth";
import loginImage from "./login-image.png";

function isEmail(inputText){
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(inputText.match(mailformat)){
        return true;
    }
    else{
        return false;
    }
}
const loggedIn = localStorage.getItem('loggedIn');
function Login() {
    const { handleSubmit, register, errors } = useForm();
    // Set States for Text Input Fields
    const [email, setEmail] = useState();
    // Functions to add text inputs to state 
    const handleEmail = (event) => {
      setEmail(event.target.value);
    }    
    const signIn = async () => {
        document.getElementById('signin-button').disabled = true;
        await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/checkUser', {
            method : 'POST',
            headers : {
                'Content-type': 'application/json'
            },
            body : JSON.stringify({ email
            })
        })
        .then(response => response.text()) 
        .then((dataStr) => {
            let data = JSON.parse(dataStr);
            let inDatabase = data.InDatabase;
            let userType = data.UserType;
            if (inDatabase === false) {
                return document.getElementById("message").innerHTML="<p>This email doesn't exist!</p>";
            } else {            
                const firebaseLogin = async () => {
                    await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/firebaseLogin', {
                        method : 'POST',
                        headers : {
                            'Content-type': 'application/json'
                        },
                        body : JSON.stringify({ email
                        })
                    })
                    .then(response => response.text()) 
                    .then((link) => {
                        // Call Send Email API
                        const emailLogin = async () => {
                            await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/emailLogin', {
                                method : 'POST',
                                headers : {
                                    'Content-type': 'application/json',
                                    'Access-Control-Allow-Origin':'*'
                                },
                                body : JSON.stringify({ email, link
                                })
                            })
                        }
                        const addToLoggedInTable = async () => {
                            await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/addToLoggedInTable', {
                                method : 'POST',
                                headers : {
                                    'Content-type': 'application/json',
                                    'Access-Control-Allow-Origin':'*'
                                },
                                body : JSON.stringify({ email
                                })
                            })
                        }
                        emailLogin();
                        addToLoggedInTable();
                    });
                }
                firebaseLogin();
                window.localStorage.setItem('UserType', userType);
                window.localStorage.setItem('emailForSignIn', email);
                document.getElementById("signin-button").style.display = "none";
                return document.getElementById("message").innerHTML="<p class='login-link-confirmation'>An email has been sent to you!</p>";
            }
        });
    }
    return (
        <div id="login">
            {loggedIn ? <Redirect to="/myaccount"/> : ""}
            <h1>Login</h1>
            <img src={loginImage} className="login-image"/>
            <p>Enter your email address and we'll email you a login link!</p>
            <form onSubmit={handleSubmit(signIn)}>
                <input className="login-input" placeholder="Email Address" name="email" onChange={handleEmail} ref={register({ required: true, validate: (input) => isEmail(input) })}  ></input>
                {errors.email && <p className="error-login">Please enter your email address.</p>}
                <button id="signin-button" className="jc-button login-button" type="submit">SUBMIT</button>
            </form>
            <div id="message"></div>
        </div>   
    )
}

export default Login;