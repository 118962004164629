export const checkoutEmail = (state = null , action) => {
    switch(action.type){
        case 'CHECKOUTEMAIL':
            return action.checkoutEmail;
        default:
            return state;
    }
}
export const checkoutFirstName = (state = null , action) => {
    switch(action.type){
        case 'CHECKOUTFIRSTNAME':
            return action.checkoutFirstName;
        default:
            return state;
    }
}
export const checkoutLastName = (state = null , action) => {
    switch(action.type){
        case 'CHECKOUTLASTNAME':
            return action.checkoutLastName;
        default:
            return state;
    }
}