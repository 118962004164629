import React, {useState} from 'react';
import './myDonations.css';
import {Redirect} from 'react-router-dom';
import Cookies from 'universal-cookie';

function MyDonations() {

    const loggedInLocal = localStorage.getItem('emailForSignIn');
    const loggedIn = localStorage.getItem('loggedIn');
    const cookies = new Cookies();

    const checkUser = async () => {
        var email = loggedInLocal;
      await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/checkUser', {
          method : 'POST',
          headers : {
              'Content-type': 'application/json'
          },
          body : JSON.stringify({ email
          })
      })
      .then(response => response.text()) 
      .then((dataStr) => {
          let data = JSON.parse(dataStr);
          let date = data.Time;
          date = date.replace(/\./g, '-');
          date = date.replace(/:/g, '-');
            if (cookies.get('jclocro') === date){

            } else {
                window.location.replace("/logout");
            }
      })
    };
    checkUser();

    const [subscriptionsMade, setSubscriptionsMade] = useState();
    const [donationsMade, setDonationsMade] = useState();
    const getDonations = async (loggedInLocal) => {
        
        await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/getDonations', {
            method : 'POST',
            headers : {
                'Content-type': 'application/json'
            },
            body : JSON.stringify({ loggedInLocal
            })
        })
        .then(response => response.text()) 
        .then((dataStr) => {
            let data = JSON.parse(dataStr);
            
            // Get Currecny and convert to icon
            var currency = data[3][0].currency;
            if (currency === 'GBP'){
                currency = '£'
            } else if (currency === 'USD'){
                currency = '$'
            } else if (currency === 'EUR'){
                currency = '€'
            }

            if (Array.isArray(data[2]) || data[2].length){
                setDonationsMade(true);
                var donations = [];
                // Loop Through Donations
                for (var i = 0; i < data[0].length; i++) {
                    // Convert Date   
                    var date = new Date(data[0][i].date);
                    var year = date.getFullYear();
                    var month = date.getMonth()+1;
                    var dt = date.getDate();

                    if (dt < 10) {
                    dt = '0' + dt;
                    }
                    if (month < 10) {
                    month = '0' + month;
                    }
                    // Gift Aid from number to words
                    var giftAid;
                    if (data[0][i].giftAid === null) {
                        giftAid = ""
                    } else {
                        giftAid = "Opted-In"
                    }
                    
                    const results1 =                 
                    `<div class="subscription-containers donation-container-my-account">
                        <p class="subscription-number-ribbon donation">Donation # - <span class="ribbon-content">${data[0][i].order_ID}</span></p>
                        <p class="subscription-masjid-name">Masjid - <span>${data[2][0].masjid_name}</span></p>
                        <p class="subscription-masjid-start">Donation Date - <span>${dt+'-' + month + '-'+year}</span></p>
                        <p>Amount - <span>${currency+ data[0][i].total_amount.toFixed(2)}</span></p>
                    </div>`
                    ;
                    donations.push(results1);
                
                }
                document.getElementById('donation-results').innerHTML = donations.join("");
            }

            // Check if subscriptions have been made
            if (Array.isArray(data[1]) || data[1].length){
                setSubscriptionsMade(true);

                // Loop Through Subscriptions
                var subscriptions = []
                for (var i = 0; i < data[1].length; i++) {
                    // Convert Dates   
                    var date = new Date(data[1][i].start_date);
                    var year = date.getFullYear();
                    var month = date.getMonth()+1;
                    var dt = date.getDate();

                    // Convert Dates   
                    var stripedate = new Date(data[1][i].stripe_next_payment);
                    var stripeyear = stripedate.getFullYear();
                    var stripemonth = stripedate.getMonth()+1;
                    var stripedt = stripedate.getDate();

                    if (dt < 10) {
                    dt = '0' + dt;
                    }
                    if (month < 10) {
                    month = '0' + month;
                    }
                    const results = 
                    `<div class="subscription-containers">
                        <p class="subscription-number-ribbon">Subscription # - <span class="ribbon-content">${data[1][i].subscription_ID}</span></p>
                        <p class="subscription-masjid-name">Masjid - <span>${data[2][0].masjid_name}</span></p>
                        <p class="subscription-masjid-frequency">Frequency - <span>${data[1][i].frequency.charAt(0).toUpperCase()+data[1][i].frequency.slice(1)}</span></p>
                        <p class="subscription-masjid-start">Start Date - <span>${dt+'-' + month + '-'+year}</span></p>
                        <p class="subscription-masjid-status">Status - <span>${data[1][i].status}</span></p>
                        ${data[1][i].status === 'Cancelled' ? '' :'<p class="subscription-masjid-next">Next Payment - <span>' + stripedt+'-' + stripemonth + '-'+stripeyear+'</span></p>'}
                        <a class="subscription-masjid-amend" href="/mydonations/recurring/${data[1][i].subscription_ID}">View/Amend</a>
                    </div>`
                    ;
                    subscriptions.push(results);
                }
                    document.getElementById('recurring-donations').innerHTML = subscriptions.join("");
            }
        })
    
    }
    getDonations(loggedInLocal);
    return (
        <div id="my-donations">
            {loggedIn ? "" : <Redirect to="/login"/>}
            { subscriptionsMade ?
                <>
                <h2>My Recurring Subscriptions</h2>
                <div id="recurring-donations"></div>
                </> : ""
            }
            { donationsMade ?
                <>
                <h2>All Donation Payments</h2>
                <div id="donation-results"></div>
                </> : 
                <div>
                    <h2>No donations made yet 😢</h2>
                    <a href="/masjid" className="jc-button">Donate Now</a>
                </div>
            }
        </div>
    )
}

export default MyDonations;