import React, {useState, useRef} from 'react';
import './masjidProfile.css';
import {Redirect} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import JoditEditor from "jodit-react";
const loggedInLocal = localStorage.getItem('emailForSignIn');
const loggedIn = localStorage.getItem('loggedIn');
var editPage = localStorage.getItem('EditPage');
var previousUpdate = localStorage.getItem('previousUpdate');
const getMasjidDetails = async () => {
    
    await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/getMasjidProfile', {
        method : 'POST',
        headers : {
            'Content-type': 'application/json'
        },
        body : JSON.stringify({ loggedInLocal
        })
    })
    .then(response => response.text()) 
    .then((dataStr) => {
        let data = JSON.parse(dataStr);
        if (data.length === 0) {
            document.getElementById("table-area").innerHTML = "<p>No data found, please contact support@jummahconnect.com to rectify this as your account may not be activiated yet.</p>"
        } 
        else {
            document.getElementById('masjidTarget').value = data[0].target;
            if (editPage === 'true') {
                if (previousUpdate === 'true') {
                    document.getElementById('masjid-update-notice').innerHTML = '<p>Your previous amends have not been approved yet, please allow 24 hours for changes to be accepted. You will be notified via email when your changes have been approved.</p>';
                    if (data[0].description_updated === null || data[0].description_updated.length < 1){
                        localStorage.setItem('masjidAbout', data[0].description);
                        localStorage.setItem('previousUpdate', false);
                        window.location.reload();
                    }
                }
            } else if (editPage === null || editPage === 'false') {
                if (data[0].description_updated === null || data[0].description_updated.length < 1){
                    localStorage.setItem('masjidAbout', data[0].description);
                    localStorage.setItem('previousUpdate', false);
                } 
                else {
                    localStorage.setItem('masjidAbout', data[0].description_updated);
                    localStorage.setItem('previousUpdate', true);
                }
                localStorage.setItem('EditPage', true);
                window.location.reload();
            }
        }
    })

}
function MasjidProfile() {


    getMasjidDetails();

	const editor = useRef(null)
    const [content, setContent] = useState('')
	
	const config = {
        toolbarAdaptive: false,
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        buttons: ['bold', 'italic', '|', 'ul', 'ol', '|',  'fontsize', '|','image', 'video', 'table', 'link', '|', 'left', 'center', 'right', 'justify', '|', 'undo', 'redo', '|', 'hr']
	}
    const { register, handleSubmit, errors } = useForm();
    const [showProgress, setShowProgress] = useState(false);
    const SubmittedForm = () => {
        return (
        <>
            { showProgress ? <CircularProgress className="checkoutsubmit" color="white"/> : null }
        </>
        )
    }
    var downloadedDescription = localStorage.getItem('masjidAbout');    

    const submitUpdates = async () => {
        setShowProgress(!showProgress)
        document.getElementById('update-masjid-button').disabled = true;
        localStorage.setItem('EditPage', false);
        localStorage.removeItem('masjidAbout');
        localStorage.removeItem('previousUpdate');
        var target = document.getElementById('masjidTarget').value
        var description = content;
        await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/putMasjidProfile', {
            method : 'POST',
            headers : {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin':'*'
            },
            body : JSON.stringify({ target, description, loggedInLocal
            })
        })                     
        .catch(err => {
            console.error(err);
        })
        const reload = () => {
            getMasjidDetails();
            window.location.reload();   
        }
        setTimeout(reload, 3000);
    }  
    function handleContent(newValue) {
        const x =  newValue.srcElement.innerHTML;
        setContent(x);
    }
    const Editor = ({}) => { 
        return (
            <JoditEditor
                ref={editor}
                value={downloadedDescription}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={handleContent} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => {}}
                id="masjidProfileDescription"
            />
        );
    } 
    return (
        <div id="masjid-profile">
            {loggedIn ? "" : <Redirect to="/login"/>}
            <h2>Masjid Profile</h2>
                <form id="masjid-update-form" onSubmit={handleSubmit(submitUpdates)}>
                    <p>Target Weekly Subscriptions</p>
                    <input type="text" id="masjidTarget" name="masjidTarget" ref={register({ required: true })}></input>
                    {errors.masjidTarget && <p className="error-checkout">Please enter your target number of weekly donations.</p>}
                    <p>Masjid About Description</p>
                    <Editor/>
                    {errors.masjidProfileDescription && <p className="error-checkout">Please enter a brief description.</p>}
                    <div id="masjid-update-notice"></div>
                    <button className="jc-button" id="update-masjid-button" type="submit">Save Changes<SubmittedForm/></button>
                </form>
            </div>
    )
}

export default MasjidProfile;