import React, {useState} from 'react';
import DonationWidget from '../../components/donationWidget/donationWidget'
import './masjid.css';
import './target-bar.css';
import {Helmet} from "react-helmet";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy';

function MasjidDetail(props) {

const url  = props.location.pathname
const masjidID = url.substring(8);
var masjidIDNew = masjidID;
masjidIDNew = masjidIDNew.replace(/[[\](')&]+/g,'');
const checkMasjidExists = async (masjidIDNew) => {
  return new Promise((resolve, reject) => {
    const getMasjidInfo = async (masjidIDNew) => {
      await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/getMasjidInfo', {
          method : 'POST',
          headers : {
              'Content-type': 'application/json',
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*'
          },
          body : JSON.stringify({ masjidIDNew
          })
      })
      .then(response => response.json()) 
      .then((dataStr) => {
          let data = dataStr;
          if (data[0] === undefined || data[0].length === 0 ){
            resolve(false);
            // Should add 404 error and status here ------------------------------------------------------
          }
          else {
            window.dataLayer.push({
              'event': 'Pageview',
              'pagePath': url,
              'pageTitle': data[0].masjid_name //some arbitrary name for the page/state
            });
            resolve(data);
          }
      });
    }
  getMasjidInfo(masjidIDNew);
  })
}
    const [downloadedMasjidName, setDownloadedMasjidName] = useState();
    const [downloadedCurrency, setDownloadedCurrency] = useState();
    const [downloadedGiftAid, setDownloadedGiftAid] = useState();
    const [masjidStatus, setMasjidStatus] = useState();
    const [doesntExist, setDoesntExist] = useState();
    
    if(doesntExist == false){
    } 
    else {
      if(downloadedMasjidName == null){
        checkMasjidExists(masjidIDNew)  
          .then(function(data2) {
            if (data2 == false){
              var errorHtml = `
              <h1>Ooop,this page doesnt exist!</h1>
              <p>Try searching for your masjid.</p>
              </br>
              <a class="jc-button" href="/masjid">Find Masjid</a>
              `
              setDoesntExist(data2);
              document.getElementById("error-container").innerHTML=errorHtml;
            } else {
              setDownloadedMasjidName(data2[0][0].masjid_name);
              setDownloadedCurrency(data2[0][0].currency);
              setDownloadedGiftAid(data2[0][0].gift_aid);
              setMasjidStatus(data2[0][0].status);
              var totalSubscribed = data2[1].length;
              var subscriptionTarget = data2[0][0].target;
              var targetPercentage = 100 - ((totalSubscribed / subscriptionTarget)*100);
              targetPercentage = targetPercentage+'%';
              if (totalSubscribed > subscriptionTarget) {
                subscriptionTarget = totalSubscribed * 1.20;
                function round5(subscriptionTarget) {
                    return Math.ceil(subscriptionTarget / 5) * 5;
                }
                subscriptionTarget = round5(subscriptionTarget);
                subscriptionTarget = subscriptionTarget.toFixed(0);
              }
              var targetCss = 'width:'+targetPercentage+';';
              var htmlToWrite =   
                  `
                  <div id="image-donation-container">
                  <div id="masjid-image-container">
                    <img id="masjid-image" alt=${data2[0][0].masjid_name} src=${data2[0][0].image == null ? 'https://s3.eu-west-2.amazonaws.com/jummahconnect.com/masjids/jummah-connect-placeholder.png' : data2[0][0].image} />
                  </div>          
                  <div id="donation-section-container">
                      <h1 id="masjid-name">${data2[0][0].masjid_name}</h1>
                      <p id="masjid-target">
                        <span class="weekly-givers">${data2[1].length}</span> of ${subscriptionTarget} Weekly Givers
                      </p>
                      <div class="progress infill"><div id="target-bar"  style="${targetCss}" class="remaining progress-status"></div></div>
                  </div>
                </div>
                <div id="masjid-content-container">
                  <Divider />
                  <p id="masjid-address">Address: ${data2[0][0].address}</p>
                  <p id="masjid-charitytax">
                    ${data2[0][0].charitytax_number == null ?'' :'Registered Charity/Tax Number <span style="font-weight:bold;">'+ data2[0][0].charitytax_number +'</span>'}
                  </p>
                  <Divider/>
                  <p id="masjid-description">
                    ${data2[0][0].description == null ? '':'<h3>About</h3>'+data2[0][0].description}
                  </p>
                </div>
              `;
              document.getElementById("main-container").innerHTML=htmlToWrite;
              setTimeout(function(){ document.getElementById("donation-section-container").appendChild(document.getElementById("widget")); document.getElementById("widget").style.display="block"}, 500);
            }
        });
      }
    }
    var masjidName = downloadedMasjidName;
    var masjidURL = url;
    const Whatsapp = () => {
        var whatsappURL = "whatsapp://send?text=Your masjid, "+ masjidName +" is listed on Jummah Connect but I can't make a donation! You need to link your bank account, so please get in touch with them - https://jummahconnect.com/masjid/"+masjidURL+""
        return (
        <li><a id="Whatsapp" className="share-button" target="new" href={whatsappURL} data-action="share/whatsapp/share"><WhatsAppIcon /></a></li>
        )
    }
    const Email = () => {
        var emailURL = "mailto:someone@gmail.com?subject=Link%20your%20account%20to%20Jummah%20Connect"+ masjidName +"!&body=Your%20masjid,%20"+ masjidName +"%20is%20listed%20on%20Jummah%20Connect%20but%20I%20can't%20make%20a%20donation!%20-%20https://jummahconnect.com/masjid/"+masjidURL+""
        return (
        <li><a id="Email" className="share-button" target="new" href={emailURL} data-action="share/email/share"><MailOutlineIcon /></a></li>
        )
    }
    var copyURL = "Your masjid, "+ masjidName +" is listed on Jummah Connect but I can't make a donation! You need to link your bank account, so please get in touch with them - https://jummahconnect.com/masjid/"+masjidURL+""
    
    function copy() {
        navigator.clipboard.writeText(copyURL);
        document.getElementById("Copy").innerHTML='<span>Copied!</span>';
    }
    const ShareLinks = () => {
      return (
        <div id="shareList">
          <p>Share this page with the masjid so we can link their bank account and help them to receive donations!</p>
            <ul>
                <Whatsapp />
                <Email />
                <li><a id="Copy" className="share-button" onClick={copy} href="#" data-action="share/copy/share"><FileCopyIcon /></a></li>
            </ul>
        </div>
      )
    }
    var metaTitle = `${downloadedMasjidName} | Jummah Connect`;
    var metaDescription = `Automate your weekly jummah donations to ${downloadedMasjidName} with Jummah Connect.`;
    return (     
      <div>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        {doesntExist === false ? '' :
        <div id="widget" style={{display:"none"}}>
          {masjidStatus === 'active' ? 
            <DonationWidget masjidID={masjidIDNew} currency={downloadedCurrency} masjidName={downloadedMasjidName} giftAid={downloadedGiftAid}/> :
            <ShareLinks/>
          }
        </div>
        }
        <div id="main-container">
        </div>
        <div id="error-container">
        </div>
      </div>
    )
}

export default MasjidDetail;