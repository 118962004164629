import React from 'react';
import './myaccount.css';
import {Link, Redirect} from 'react-router-dom';
import * as firebase from "firebase/app";
import auth from "firebase/auth";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {loggedInEmail}  from '../../reducers/actions';
import { useSelector, useDispatch } from 'react-redux'; 
import Cookies from 'universal-cookie';

var firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
};
if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
}

// Confirm the link is a sign-in with email link.
if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
    const cookies = new Cookies();
    const checkUser = async () => {
      await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/checkUserAddedToLoggedIn', {
          method : 'POST',
          headers : {
              'Content-type': 'application/json'
          },
          body : JSON.stringify({ email
          })
      })
      .then(response => response.text()) 
      .then((dataStr) => {
          let data = JSON.parse(dataStr);
          console.log(data);
          let userType = data.UserType;
          let inDatabase = data.InDatabase;
          let date = data.Time;
          if (inDatabase) {
            date = date.replace(/\./g, '-');
            date = date.replace(/:/g, '-');
            cookies.set('jclocro', date);
            window.localStorage.setItem('UserType', userType);
            window.localStorage.setItem('emailForSignIn', email);
            window.location.reload();
          } else {
            window.alert('This email cannot be verified, please try again.');
            window.location.reload();
          }
      })
    };
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    var email = window.localStorage.getItem('emailForSignIn');
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('It looks like you are trying to login from a different device. Please provide your email for confirmation');
      checkUser(email);
      //setTimeout(window.location.reload(), 2000);
    }
    if (cookies.get('jclocro') == null){
      checkUser();
    }
    // The client SDK will parse the code from the link for you.
    firebase.auth().signInWithEmailLink(email, window.location.href)
      .then(function(result) {
        // Clear email from storage.
        //   window.localStorage.removeItem('emailForSignIn');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch(function(error) {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
}
if (window.location.href.indexOf("apiKey=") > -1) {
    localStorage.setItem('loggedIn',true);
}

const loggedInLocal = localStorage.getItem('emailForSignIn');
const userType = localStorage.getItem('UserType');
const loggedIn = localStorage.getItem('loggedIn');

function MyAccount() {

// Get LoggedInEmail from Redux State
const loggedInEmailVar = useSelector(state => state.loggedInEmail);
const dispatch = useDispatch();

if (loggedInEmailVar === null){
  if (loggedIn === 'true') {
    dispatch(loggedInEmail(loggedInLocal));
  }
}
    return (
        <div id="myAccount">
            {loggedIn ? "" : <Redirect to="/login"/>}
            <h1>My Account</h1>
            <ul className="account-list">
                {userType === 'masjid_admin' ? <Link to="/masjiddonations"><li>Masjid Donations</li></Link> : ""}
                {userType === 'masjid_admin' ? <Link to="/masjidprofile"><li>Edit Masjid Details</li></Link> : ""}
                {userType === 'masjid_admin' ? <Link to="/masjidstripe"><li>Stripe</li></Link> : ""}
                <Link to="/mydonations"><li>My Donations</li></Link>
                <Link to="/logout"><li><span id="logout-bar">Logout <ExitToAppIcon/></span></li></Link>
            </ul>
        </div>
    )
}

export default MyAccount;