import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import './register.css';
import * as firebase from "firebase/app";
import auth from "firebase/auth";

function Register () {
    const { handleSubmit, register, errors } = useForm();

    // Set States for Text Input Fields
    const [email, setEmail] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [masjidName, setMasjidName] = useState();
    const [masjidAddress, setMasjidAddress] = useState();
    const [cityTown, setCityTown] = useState();
    const [postcode, setPostcode] = useState();
    const [mobile, setMobile] = useState();

    // Functions to add text inputs to state 
    const handleEmail = (event) => {
      setEmail(event.target.value);
    }
    const handleFirstName = (event) => {
        setFirstName(event.target.value);
    }
    const handleLastName = (event) => {
        setLastName(event.target.value);
    }
    const handleMasjidName = (event) => {
        setMasjidName(event.target.value);
    }
    const handleMasjidAddress = (event) => {
        setMasjidAddress(event.target.value);
    }
    const handleCityTown = (event) => {
      setCityTown(event.target.value);
    }
    const handlePostcode = (event) => {
        setPostcode(event.target.value);
    }
    const handleMobild = (event) => {
      setMobile(event.target.value);
    }
    const registerMasjid = async () => {
      await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/checkUser', {
          method : 'POST',
          headers : {
              'Content-type': 'application/json'
          },
          body : JSON.stringify({ email
          })
      })
      .then(response => response.text()) 
      .then((dataStr) => {
          let data = JSON.parse(dataStr);
          let inDatabase = data.InDatabase;
          let userType = data.UserType;
          if (userType === 'masjid_admin') {
            document.getElementById("register-button").style.display = "none";
              return document.getElementById("message").innerHTML="<p>This email address is already registered with a masjid! Login with your account</p><a class='jc-button' href='/login'>Login</a>";
          } else {
            const firebaseRegister = async () => {
              await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/firebaseRegister', {
                method : 'POST',
                headers : {
                    'Content-type': 'application/json'
                },
                body : JSON.stringify({ email, firstName, lastName, masjidName, masjidAddress, cityTown, postcode, mobile
                })
              })
            }
            firebaseRegister();
            // Save User Type and Email to localstorage
            window.localStorage.setItem('UserType', 'masjid_admin');
            window.localStorage.setItem('emailForSignIn', email);
            document.getElementById("register-button").style.display = "none";
            return document.getElementById("message").innerHTML="<p>An email has been sent to you, don't forget to check your spam!</p>";
          }
      });
    }
  return (
    <div id="registerForm-container">
      <h1>Register</h1>
      <p>This service is 100% FREE to be listed due to Covid-19.</p>
      <p>If a masjid that you attend isn’t on the website, submit your suggestion here and we’ll add it once we’ve verified it exists.</p>
      <form id="registerForm" onSubmit={handleSubmit(registerMasjid)}>
        <div>
            <input className="register-input-field" placeholder="Email Address" name="email" onChange={handleEmail} ref={register({ required: true })} autoFocus></input>
            {errors.email && <span>Please enter your email address.</span>}
        </div>
        <div>
            <input className="register-input-field" placeholder="First Name" name="firstName" onChange={handleFirstName} ref={register({ required: true })}></input>
            {errors.firstName && <span>Please enter your first name.</span>}
        </div>
        <div>
            <input className="register-input-field" placeholder="Last Name" name="lastName" onChange={handleLastName} ref={register({ required: true })}></input>
            {errors.lastName && <span>Please enter your last name.</span>}
        </div>
        <div>
            <input className="register-input-field" placeholder="Masjid Name" name="masjidName" onChange={handleMasjidName} ref={register({ required: true })}></input>
            {errors.masjidName && <span>Please enter the Masjid name.</span>}
        </div>
        <div>
            <input className="register-input-field" placeholder="Masjid Address" name="masjidAddress" onChange={handleMasjidAddress} ref={register({ required: true })}></input>
            {errors.masjidAddress && <span>Please enter the Masjid address.</span>}
        </div>
        <div>
            <input className="register-input-field" placeholder="City/Town" name="cityTown" onChange={handleCityTown} ref={register({ required: true })}></input>
            {errors.cityTown && <span>Please enter the City/Town.</span>}
        </div>
        <div>
            <input className="register-input-field" placeholder="Post/Zip Code" name="postcode" onChange={handlePostcode} ref={register({ required: true })}></input>
            {errors.postcode && <span>Please enter the Post/Zip Code.</span>}
        </div>
        <div>
            <input className="register-input-field" placeholder="Mobile Number" name="mobile" onChange={handleMobild} ref={register({ required: true })}></input>
            {errors.mobile && <span>Please enter your mobile number.</span>}
        </div>
      <p id="message">You’ll need to verify your email address once you have submitted, so check your inbox or spam folder!</p>
        <button id="register-button" className="jc-button" type="submit">&#128274; Submit</button> 
        <div id="terms">
            <p>Your personal data will be used to process your submission, support your experience throughout this website, and for other purposes described in our <a target="new" href="https://jummahconnect.com/privacy-policy">privacy policy</a>.</p>
        </div>
      </form>
    </div>
  );
}

export default Register;