import React, {useState} from 'react';
function cancelSubscription(stripeSubscriptionID,loggedInLocal) {
    const cancelSubscription = async (stripeSubscriptionID,loggedInLocal) => {
        
        await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/stripeCancelSubscription', {
            method : 'POST',
            headers : {
                'Content-type': 'application/json'
            },
            body : JSON.stringify({ stripeSubscriptionID,loggedInLocal
            })
        })
    }
    cancelSubscription(stripeSubscriptionID,loggedInLocal);
    const reload = () => {
        window.location.reload();   
    }
    setTimeout(reload, 1000);
}
export default  cancelSubscription;