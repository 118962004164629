import React, {useState} from 'react';
import './masjidStripe.css';
import {Redirect} from 'react-router-dom';
var url = require('url');

const loggedInLocal = localStorage.getItem('emailForSignIn');
const loggedIn = localStorage.getItem('loggedIn');
const adr  = window.location.href;
var q = url.parse(adr, true);
var qdata = q.query; 
const urlStripeID = qdata.code;

// If URL is from Stripe redirect
if (adr.includes("&code=") === true) {
    const submitConnectID = async () => {
        await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/putMasjidStripe', {
            method : 'POST',
            headers : {
                'Content-type': 'application/json'
            },
            body : JSON.stringify({ loggedInLocal, urlStripeID
            })
        })
    }
    submitConnectID();
}

function MasjidStripe() {
    const [stripe, setStripe] = useState();
    const getMasjidStripe = async () => {
        await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/getMasjidStripe', {
            method : 'POST',
            headers : {
                'Content-type': 'application/json'
            },
            body : JSON.stringify({ loggedInLocal
            })
        })
        .then(response => response.text()) 
        .then((dataStr) => {
            let data = JSON.parse(dataStr);
            if (data[0].length === 0) {
                setStripe(false);
            } else if (data[0][0].stripe_connect_ID === null) {
                setStripe(false);
                var address = data[1][0].address_1; address = encodeURIComponent(address.trim());
                var first_name = data[1][0].first_name; first_name = encodeURIComponent(first_name.trim());
                var last_name = data[1][0].last_name; last_name = encodeURIComponent(last_name.trim());
                var postcode = data[1][0].postcode; postcode = encodeURIComponent(postcode.trim());
                var towncity = data[1][0].towncity; towncity = encodeURIComponent(towncity.trim());
                var phone = data[1][0].phone; 
                var masjid_name = data[1][0].masjid_name; masjid_name = encodeURIComponent(masjid_name.trim());
                var urlLink = `<a href="https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=ca_Gwp58FuUsVGO4xdcbF1zuqFeObBk3eV1&scope=read_write&stripe_user[email]=${data[1][0].user_ID}&stripe_user[country]=UK&stripe_user[business_name]=${masjid_name}&stripe_user[business_type]=non_profit&stripe_user[first_name]=${first_name}&stripe_user[last_name]=${last_name}&stripe_user[url]=https://jummahconnect.com/masjid/${data[1][0].masjid_ID}&stripe_user[street_address]=${address}&stripe_user[city]=${towncity}&stripe_user[zip]=${postcode}&stripe_user[currency]=gbp&stripe_user[phone_number]=${phone}&redirect_uri=https://jummahconnect.com/masjidstripe" class="stripe-connect"><span>Create Stripe Account</span></a>`;
                document.getElementById("no-stripe").innerHTML = urlLink;  
            } else {
                setStripe(true); 
            }
        })
    }
    getMasjidStripe(); 
    return (
        <div id="stripe-masjid-container">
            {loggedIn ? "" : <Redirect to="/login"/>}
            <h2>Stripe</h2>
            {stripe ? 
            <div>
                <p>Your Stripe Account is successfully connected and you can now receive donations.</p>
                <p>The Jummah Connect team is working on approving your account and will notify you by email once your page is activated.</p>
                <a class="jc-button return-button" href="https://jummahconnect.com/myaccount">Return to My Account</a>
            </div>
            :
            <div className="stripe-option-container">
                <p>Thanks for verifying your email. The next step is to link your bank account to Jummah Connect.</p>
                <p>Choose from one of the options below:</p>
                <div className="stripe-option">
                    <p>We do have a Stripe account.</p>
                    <a href="https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=ca_Gwp58FuUsVGO4xdcbF1zuqFeObBk3eV1&scope=read_write&stripe_landing=login&redirect_uri=https://jummahconnect.com/masjidstripe" className="stripe-connect"><span>Login with Stripe</span></a>
                </div>
                <div className="stripe-option">
                    <p>We do not have a Stripe account.</p>
                    <div id="no-stripe" ></div>
                </div>
            </div>
            }
        </div>
    )
}

export default MasjidStripe;