import React, {useState} from 'react';
import './checkoutForm.css';
import { useForm } from 'react-hook-form';
import { Redirect, Link } from 'react-router-dom';
import { selectedSupportAmount } from '../../reducers/actions';
import { useSelector, useDispatch } from 'react-redux'; 
import { saveSelected, loadSelected } from '../../localStorage';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Divider from '@material-ui/core/Divider';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';

function isEmail(inputText){
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(inputText.match(mailformat)){
        return true;
    }
    else{
        return false;
    }
}
function CheckoutForm(props) {
    // Get React Hook Form Methods
    const { handleSubmit, register, errors } = useForm();
    var saved = loadSelected();

    // Initiate Stripe 
    const stripe = useStripe();
    const elements = useElements();

    // Get Submitted values from DonationWidget - Amount, Frequency, Masjid
    const selectedAmount = useSelector(state => state.selectedAmount);
    const selectedMasjidName = useSelector(state => state.selectedMasjidName);
    const selectedMasjidID = useSelector(state => state.selectedMasjidID);
    const selectedFrequency = useSelector(state => state.selectedFrequency);
    const giftAid = useSelector(state => state.giftAidApplicable);
    const currency = useSelector(state => state.masjidCurrency);
    var donationFees;
    var summaryAmount;
    summaryAmount = selectedAmount * 1;
    summaryAmount = summaryAmount.toFixed(2);

    // Set States for Support Amount and Custom Support Amount
    const [supportAmount, setsupportAmount] = useState(0.1);
    
    // Calculate the Support Amount on page load and dispatch to Redux
    var calculatedAmount = selectedAmount * supportAmount;
    calculatedAmount = calculatedAmount.toFixed(2);
    const dispatch = useDispatch();
    dispatch(selectedSupportAmount(calculatedAmount));
    
    // Function to handle support amount change and check if other selected
    const handleChangeSupportAmount = (event) => {
        setsupportAmount(event.target.value);
        if (event.target.value === '0') {
            setShowSupportSummary(!showSupportSummary)
        } else {
            setShowSupportSummary(true)
        } 
    }
    // Support Summary if selected
    const SupportSummary = () => {
        return (
            <tr className="fee">
                <th>Jummah Connect Support</th>
                <td><span>{currency}{calculatedAmount}</span></td>
            </tr>
        )
    }
    const [showSupportSummary, setShowSupportSummary] = useState(true);
    const SupportSummaryLogic = () => {
        return (
        <>
            { showSupportSummary ? <SupportSummary /> : null }
        </>
        )
    }
    // Set States for Text Input Fields
    const [email, setEmail] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [address, setAddress] = useState();
    const [townCity, setTownCity] = useState();
    const [postcode, setPostcode] = useState();
    const [phone, setPhone] = useState();
    const [optin, setOptin] = useState();
    const [optinMasjid, setOptinMasjid] = useState();
    const [GiftAid, setGiftAid] = useState();

    // Functions to add text inputs to state 
    const handleEmail = (event) => {
        setEmail(event.target.value);
    }
    const handleFirstName = (event) => {
        setFirstName(event.target.value);
    }
    const handleLastName = (event) => {
        setLastName(event.target.value);
    }
    const handleAddress = (event) => {
        setAddress(event.target.value);
    }
    const handleTownCity = (event) => {
        setTownCity(event.target.value);
    }
    const handlePostcode = (event) => {
        setPostcode(event.target.value);
    }
    const handleOptin = (event) => {
        var checkedOptin = 0;
        if (document.getElementById('optInEmail').checked) {
            checkedOptin = 1;
        } else {
            checkedOptin = 0;
        }
        setOptin(checkedOptin);
    }
    const handleOptinMasjid = (event) => {
        var checkedMasjid = 0;
        if (document.getElementById('optInMasjid').checked) {
            checkedMasjid = 1;
        } else {
            checkedMasjid = 0;
        }
        setOptinMasjid(checkedMasjid);
    }
        var checkedGiftAid = 0;
    const handleGiftAid = (event) => {
        if (document.getElementById('giftAid').checked) {
            checkedGiftAid = 1;
        } else {
            checkedGiftAid = 0;
        }
        setGiftAid(checkedGiftAid);
    }
    const handlePhone = (event) => {
        setPhone(event.target.value);
    }

    // Save text input to localStorage 
    const saveTextInputs = () => {
        saveSelected({
            checkoutFirstName:firstName
        });
    }

    // Gift Aid Summary if selected from tick box
    var giftAidAmount = summaryAmount * 0.25;
    giftAidAmount = parseFloat(giftAidAmount).toFixed(2);
    var giftAidCalculated = +giftAidAmount + +summaryAmount;
    giftAidCalculated = parseFloat(giftAidCalculated).toFixed(2);

    const GiftAidFormFields = () => {
        return (
            <tr id="giftAidSummary">
                <td>Gift Aid Donation ({selectedFrequency})</td>
                <td><span>{currency}{giftAidAmount}</span></td>
            </tr>
        )
    }
    const [showGiftAid, setShowGiftAid] = useState(false);
    const onClickGiftAid = () => {
        setShowGiftAid(!showGiftAid)
    }
    const GiftAidLogic = () => {
        return (
        <>
            { showGiftAid ? <GiftAidFormFields /> : null }
        </>
        )
    }
    
    const [showProgress, setShowProgress] = useState(false);
    const SubmittedForm = () => {
        return (
        <>
            { showProgress ? <CircularProgress className="checkoutsubmit" color="white"/> : null }
        </>
        )
    }
    
    const [checkoutComplete, setCheckoutComplete] = useState(false);

    // Calculate Support Fees for Dropdown
    var percent5 = selectedAmount * 0.05;
    percent5 = parseFloat(percent5).toFixed(2);    
    var percent10 = selectedAmount * 0.10;
    percent10 = parseFloat(percent10).toFixed(2);
    var percent15 = selectedAmount * 0.15;
    percent15 = parseFloat(percent15).toFixed(2);
    // Calculate Grand Total
    donationFees = (selectedAmount * 0.04) + .2;
    donationFees = donationFees.toFixed(2);
    var grandTotal = parseFloat(donationFees) + parseFloat(calculatedAmount) + parseFloat((selectedAmount * 1));
    grandTotal = grandTotal.toFixed(2);
    // On Form Submit Function
    const submitCheckout = async () => {

        setShowProgress(!showProgress)
        document.getElementById('checkout-button').disabled = true;

        if (!stripe || !elements) {
            return;
          }
          const card = elements.getElement(CardElement);
          const result = await stripe.createToken(card);
          if (result.error) {
            console.log(result.error.message);
          } else {
            let amount = grandTotal;
            let fees = donationFees * 100;
            let support = calculatedAmount * 100;
            let token = result.token;
            if (selectedFrequency === 'onetime') {
                await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/stripeOneTime', {
                    method : 'POST',
                    headers : {
                        'Content-type': 'application/json',
                        'Access-Control-Allow-Origin':'*'
                    },
                    body : JSON.stringify({ token, amount, fees, support, email, firstName, lastName ,address, townCity, postcode, phone, optin, optinMasjid, GiftAid, selectedMasjidID, selectedMasjidName, currency
                     })
                }) 
                .then(response => response.text()) 
                .then((dataStr) => {
                    let onetimeResponse = JSON.parse(dataStr)
                    dataLayerSubmission(onetimeResponse);
                })                    
                .then(() => setTimeout(() => setCheckoutComplete(true), 100))
                .catch(err => {
                    console.error(err);
                })
            } else if (selectedFrequency === 'weekly') {
                await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/stripeWeekly', {
                    method : 'POST',
                    headers : {
                        'Content-type': 'application/json',
                        'Access-Control-Allow-Origin':'*'
                    },
                    body : JSON.stringify({ token, amount, fees, support, email, firstName, lastName ,address, townCity, postcode, phone, optin, optinMasjid, GiftAid, selectedMasjidID, selectedMasjidName, currency
                     })
                })
                .then(response => response.text()) 
                .then((dataStr) => {
                    let weeklyResponse = JSON.parse(dataStr)
                    dataLayerSubmission(weeklyResponse);
                })   
                .then(() => setTimeout(() => setCheckoutComplete(true), 100))
                .catch(err => {
                    console.error(err);
                })
            } else if (selectedFrequency === 'monthly') {
                await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/stripeMonthly', {
                    method : 'POST',
                    headers : {
                        'Content-type': 'application/json',
                        'Access-Control-Allow-Origin':'*'
                    },
                    body : JSON.stringify({ token, amount, fees, support, email, firstName, lastName,address, townCity, postcode, phone, optin,optinMasjid, GiftAid, selectedMasjidID, selectedMasjidName, currency
                     })
                })
                .then(response => response.text()) 
                .then((dataStr) => {
                    let monthlyResponse = JSON.parse(dataStr)
                    dataLayerSubmission(monthlyResponse);
                })   
                .then(() => setTimeout(() => setCheckoutComplete(true), 100))
                .catch(err => {
                    console.error(err);
                })

            }
          }
        function dataLayerSubmission (orderIDVariable){
            return (
                window.dataLayer.push({
                    event: 'eec.purchase',
                    'ecommerce': {
                    'purchase': {
                        'actionField': {
                        'id': orderIDVariable,                         // Transaction ID. Required for purchases and refunds.
                        'affiliation': 'Website',
                        'revenue': grandTotal,                     // Total transaction value (incl. tax and shipping)
                        'tax':'0.00',
                        'shipping': '0.00'
                        },
                        'products': [{                            // List of productFieldObjects.
                            'name': selectedMasjidName,     // Name or ID is required.
                            'id': '',
                            'price': selectedAmount,
                            'brand': 'Jummah Connect Masjid',
                            'category': 'Masjid',
                            'variant': '',
                            'quantity': 1,
                            'coupon': ''                            // Optional fields may be omitted or set to empty string.
                            },{                            // List of productFieldObjects.
                            'name': 'Support Amount',     // Name or ID is required.
                            'id': '',
                            'price': calculatedAmount,
                            'brand': 'Jummah Connect Support',
                            'category': 'Jummah Connect Support',
                            'variant': '',
                            'quantity': 1,
                            'coupon': ''                            // Optional fields may be omitted or set to empty string.
                            },{                            // List of productFieldObjects.
                            'name': 'Fees',     // Name or ID is required.
                            'id': '',
                            'price': donationFees,
                            'brand': 'Jummah Connect Fees',
                            'category': 'Jummah Connect Fees',
                            'variant': '',
                            'quantity': 1,
                            'coupon': ''                            // Optional fields may be omitted or set to empty string.
                            }]
                    }
                    }
                })
            )
        }
    }

    if (selectedAmount === null) {
        return (
            <div id="checkoutForm">
                <p className="notice">Looks like you need to donate to a masjid before you can checkout.</p>
                <Link className="jc-button" to="/masjid">Find Masjid</Link>
            </div>   
        )
    } 
    else {
        return (
            <div id="checkoutForm">                
                <form onSubmit={handleSubmit(submitCheckout)}>
                    <div id="contactInfo">
                        <div>
                            <input className="checkout-input-field" name="email" placeholder="Email Address" value={email} onChange={handleEmail} ref={register({ required: true, validate: (input) => isEmail(input) })} autoFocus></input>
                            {errors.email && <p className="error-checkout">Please enter your email address.</p>}
                        </div>
                        <div>
                            <input className="checkout-input-field" name="fName" placeholder="First Name" value={firstName} onChange={handleFirstName} ref={register({ required: true })}></input>
                            {errors.fName && <p className="error-checkout">Please provide your first name.</p>}
                        </div>
                        <div>
                            <input className="checkout-input-field" name="lName" placeholder="Last Name" value={lastName} onChange={handleLastName} ref={register({ required: true })}></input>
                            {errors.lName && <p className="error-checkout">Please provide your last name.</p>}
                        </div>
                        {giftAid ? 
                        <div>
                            <input className="checkout-input-field" name="phone" placeholder="Phone" value={phone} onChange={handlePhone} type="number" ref={register({ required: true })}></input>
                        {errors.phone && <p className="error-checkout">Please enter your phone number.</p>}
                        </div> 
                        : ''}
                        {giftAid ? 
                        <div>
                            <div>
                                <input className="checkout-input-field" name="addressFinder" placeholder="Address" value={address} onChange={handleAddress} ref={register({ required: true })}></input>
                            {errors.addressFinder && <p className="error-checkout">Please enter your address.</p>}
                            </div>
                            <div>
                                <input className="checkout-input-field" name="townCity" placeholder="Town or City" value={townCity} onChange={handleTownCity} ref={register({ required: true })}></input>
                            {errors.townCity && <p className="error-checkout">Please enter your town or city.</p>}
                            </div>
                            <div>
                                <input className="checkout-input-field" name="postcode" placeholder="Post/Zip Code" value={postcode} onChange={handlePostcode} ref={register({ required: true })}></input>
                            {errors.postcode && <p className="error-checkout">Please enter your postal/zip code.</p>}
                            </div> 
                        </div>
                        : ''}
                        <Divider className="checkout-divider" />
                        <div className="optin-container">
                            <input id="optInEmail" onChange={handleOptin} name="optInEmail" type="checkbox"></input>
                            <label htmlFor="optInEmail">Opt in for emails from Jummah Connect to get updates about your donations and other Masjids!</label>
                        </div>
                        <div className="optin-container">
                            <input id="optInMasjid" onChange={handleOptinMasjid} name="optInMasjid" type="checkbox"></input>
                            <label htmlFor="optInMasjid">I'm happy for {selectedMasjidName} to contact me by email in the future.</label>
                        </div>
                        {giftAid ? 
                        <div>
                            <div>
                            <Divider className="checkout-divider" />
                            </div>
                            <div className="giftAidContainer">
                                <img alt="giftAidLogo" src="https://upload.wikimedia.org/wikipedia/en/thumb/9/9f/Gift_Aid_UK_Logo.svg/1024px-Gift_Aid_UK_Logo.svg.png" width="75px"/>
                                <p><b>Your donation of {currency}{summaryAmount} can become {currency}{giftAidCalculated}.</b></p>
                                <input id="giftAid" onChange={handleGiftAid} onClick={onClickGiftAid} name="giftAid" type="checkbox"></input>
                                <label  className="small-print" htmlFor="giftAid">I am a UK taxpayer and would like {selectedMasjidName} to increase my donation by 25% through Gift Aid. My data will be shared with {selectedMasjidName} to process Gift Aid.</label>
                                <p className="small-print">I understand that if I pay less income/capital gains tax than the amount of Gift Aid claimed on all my donations in the tax year in which they are received, it is my responsibility to pay any difference and that I must notify {selectedMasjidName} of any changes to my tax status, including changes to my name or address.</p>
                            </div>
                        </div>
                        : ''}
                    </div>
                        <Divider className="checkout-divider" />
                    <div>
                        <h3>Keep this website FREE for all Masjids</h3> 
                        <p>Unlike most donation websites, we don't take a cut from your donation to ensure <b>the Masjid gets 100% of your donation</b>, please support Jummah Connect.</p>
                        <div id="cover-donation-fees">
                            <select id="support-select" onChange={handleChangeSupportAmount}>
                                <option value='0.05' >Small - ({currency}{percent5})</option>
                                <option value='0.10' selected >Medium - ({currency}{percent10})</option>
                                <option value='0.15' >Large - ({currency}{percent15})</option>
                                <option value="0">I'll make a Dua</option>
                            </select>
                        </div>
                    </div>  
                        <Divider className="checkout-divider" />              
                    <div id="checkoutSummaryContainer">
                        <h3>Summary</h3>
                        <table id="summary-table">
                            <thead>
                                <tr>
                                    <th className="product-name">Item</th>
                                    <th className="product-total">Amount</th>
                                </tr>
                            </thead>
                                <tbody>
                                    <tr className="item">
                                        <td className="product-name">{selectedMasjidName}</td>
                                        <td className="product-total">
                                            <span className="subtotal">
                                                <span className="amount">{currency}{summaryAmount}</span>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            <tfoot>
                                <SupportSummaryLogic />
                                <tr className="fee">
                                    <th>100% Donation <span className="small-print">(4% + {currency}0.20)</span></th>
                                    <td><span>{currency}{donationFees}</span></td>
                                </tr>
                                <tr className="order-total">
                                    <th>Total ({selectedFrequency})</th>
                                    <td><strong><span>{currency}{grandTotal}</span></strong> </td>
                                </tr>
                                <GiftAidLogic />
                            </tfoot>
                        </table>
                    </div>
                    <Divider className="checkout-divider" />                
                    <div id="paymentContainer">
                        <div id="cardPayment">
                            <CardElement />
                        </div>
                    </div>
                    <button className="jc-button checkout-button" type="submit" id="checkout-button"><span id="pay-button"><HttpsOutlinedIcon fontSize="small"/>Pay Securely<SubmittedForm/></span></button>  
                    <div id="terms">
                        <p>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our <a target="new" href="https://jummahconnect.com/privacy-policy">privacy policy</a>.</p>
                    </div>
                </form>
                <img src={window.location.origin + '/jummah-connect-logo.png'} width="150px" alt="Jummah Connect Logo"/>
                {checkoutComplete ? <Redirect to="/share" /> : null}
            </div>
        )
    }
}

export default CheckoutForm;