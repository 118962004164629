import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './masjid.css';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

window.dataLayer = window.dataLayer || [];

var date = new Date;
date = date.getTime();
var ONE_MIN=10000;
var dateFromLocal = localStorage.getItem('timestamp');
localStorage.setItem('timestamp',(date));

if((date - ONE_MIN) > dateFromLocal) {
  localStorage.setItem('timestamp',(date)); 
  const fetchMasjids = async () => {
    const data = await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/getMasjidsFromS3',{        
      method: 'GET'
    })
    .then(response => response.text()) 
    .then((dataStr) => {
        let masjids = dataStr;
        localStorage.removeItem('downloadedMasjids');
        localStorage.setItem('downloadedMasjids',(masjids));
    });
  }
  fetchMasjids(); 
}

window.dataLayer.push({
  'event': 'Pageview',
  'pagePath': '/masjid',
  'pageTitle': 'Masjid Search' //some arbitrary name for the page/state
});
function Masjid () {
  const useStyles = makeStyles((theme) => ({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      margin: '10px 30px 0 30px',
    },
    button: {
      marginLeft: theme.spacing(1),
      flex: 1,
      typography: {
          fontSize: 10,
      },
    },
    iconButton: {
      padding: 5,
      width:15,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }));
  const classes = useStyles();

  const [filteredMasjid, setFilteredMasjids] = useState();
  const setResults = (e) => {
    const {value} = e.target;
    setFilteredMasjids(value);
  }

  let masjidsLocal = localStorage.getItem('downloadedMasjids');
  masjidsLocal = JSON.parse(masjidsLocal);

  return (
    <div id="masjid-search-container">
      <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        inputProps={{ 'aria-label': 'search google maps' }}
        onChange={setResults}
        placeholder='Start typing to find your masjid'
        autoFocus
      />
      <SearchIcon className={classes.iconButton} aria-label="search"/>
      </Paper>
      <div className="masjid-list">
        {masjidsLocal?.filter((item:any) => 
          item?.masjid_name.toLowerCase().includes(filteredMasjid?.toLowerCase())).map((item:any) => ( 
            <div>
              <Link key={item.masjid_ID} to={{pathname: `/masjid/${item.masjid_name.replace(/\s+/g,"-").toLowerCase()}`,}}>
                <div className="masjid-result-container">
                      <div className="image-container">
                      <img src={item.image == null ? 'https://s3.eu-west-2.amazonaws.com/jummahconnect.com/masjids/jummah-connect-placeholder.png' : item.image} />
                      </div>
                      <div className="name-address-container">
                        <span className="masjid-name">{item.masjid_name}</span><br></br>
                        <span className="masjid-address">{item.address}</span>
                      </div>
                </div>
              </Link>
            </div>
            ))}
      </div>
    </div>
  );
}

export default Masjid;