import {combineReducers} from 'redux';
import loggedReducer from './isLoggedIn';
import selectedMasjidName from './selectedMasjidName';
import selectedMasjidID from './selectedMasjidID';
import selectedAmount from './selectedAmount';
import selectedFrequency from './selectedFrequency';
import selectedSupportAmount from './selectedSupportAmount';
import {checkoutEmail,checkoutFirstName,checkoutLastName} from './checkout';
import giftAidApplicable from './giftAid';
import masjidCurrency from './masjidCurrency';
import loggedInEmail from './loggedInEmail';

const allReducers = combineReducers({
    loggedIn : loggedReducer,
    selectedMasjidName : selectedMasjidName,
    selectedMasjidID : selectedMasjidID,
    selectedAmount : selectedAmount,
    selectedFrequency : selectedFrequency,
    selectedSupportAmount : selectedSupportAmount,
    checkoutEmail : checkoutEmail,
    checkoutFirstName : checkoutFirstName,
    checkoutLastName : checkoutLastName,
    giftAidApplicable:giftAidApplicable,
    masjidCurrency:masjidCurrency,
    loggedInEmail:loggedInEmail
})

export default allReducers;