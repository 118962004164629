import React from 'react';

function TermsAndConditions() {
    window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': '/terms-and-conditions',
        'pageTitle': 'Terms and Conditions' //some arbitrary name for the page/state
    });
    return (
        <div id="terms-container">
            <h1>Terms and Conditions</h1>            
                                    
            <p>Updated: 11th May 2020</p>
            

            
            <p>This is
            Jummah Connect, a platform designed specifically for connecting organizations
            and donors/givers for the purpose of receiving/giving donations. This website
            and its provided services can, therefore, be used by both receiving
            organizations and donors/givers.</p>
            

            
            <p>By using
            our website and its services in any way, whether as an organization or as a
            giver,&nbsp; you agree to be lawfully bound by
            these terms and conditions hereafter detailed. You must first read these terms
            and conditions carefully and in their entirety before using the website,
            contents, services, and any other information related to this website, and must
            stop using the service immediately if you do not agree with any or all of our
            terms.</p>
            

            
            <p>We may
            change, adjust, completely or partially delete and/or add to these terms as per
            our discretion at any time without giving you or any other user prior notice,
            and you accept that the revised/modified terms and conditions will come into
            effect and become legally binding immediately upon our posting of it.</p>
            

            
            <p>Any use
            of the website and services after a reviewed/modified agreement is uploaded
            will be considered as your consent to continue to be legally bound by the
            terms. This particular agreement shall supersede and overthrow all previous
            versions of the Terms and Conditions.</p>
            

            
            <h3><strong>DEFINITION
            OF TERMS</strong></h3>
            

            
            <p>In these
            terms and conditions, the following words shall be deemed to have the following
            meanings:</p>
            

            
            <p>Us, we,
            our, the company: This shall refer to Jummah Connect</p>
            

            
            <p>You,
            your, user: This shall refer to the person/organization using Jummah Connect's
            services whether as a receiving organization or as a giver/donor</p>
            

            
            <p>Organization:
            This shall be deemed to mean a user who has undergone our registration process
            in order to receive funds from donors/givers through our service</p>
            

            
            <p>Giver/donor:
            This shall refer to a user who uses the services to make a financial donation
            to an organization registered on the services to so receive such donations</p>
            

            
            <p>Services:
            This shall refer to Jummah Connect's connection services as per allowing
            organizations to easily receive funds while allowing givers to make donations
            to organizations of their choice</p>
            

            
            <p>Website:
            This shall refer to (please insert the website)</p>
            

            
            <p>Law:
            This shall refer to the laws of the United Kingdom.</p>
            

            
            <h3><strong>USING
            THE WEBSITE</strong></h3>
            

            
            <p>This
            website and its services are made available for the use of individuals who are
            at least 18 years old or the age of consent in their jurisdictions. The service
            is available for both personal and business use, and we grant eligible users a
            limited, rescindable, non-exclusive, non-assignable, and non-sublicensable
            license to use the website and its services, as long as such individuals/businesses
            fully comply with all the provisions of these terms of service.</p>
            

            
            <p>While we
            operate out of the United Kingdom, our services are targeted at a global
            audience and are accessible from all parts of the world. We, however, do not
            guarantee or warrant that any service which we provide is suitable or otherwise
            available for use outside of the United Kingdom. International users understand
            and accept that they connect to and use our website and services at their own
            expense and risk and are fully accountable for compliance with all relevant
            local laws.</p>
            

            
            <h3><strong>USER
            ACCOUNTS</strong></h3>
            

            
            <p>We allow
            casual visitors to access and view our website. In order to use the services as
            a receiving organization, you, however, will have to undergo a registration
            process in order to have access to the full array of our services. To register,
            we will ask you to provide important information such as your legal name,
            physical address, email address, contact details, contact person, and any other
            information we may deem pertinent. Refusal to provide us with this information
            may mean that we are unable to register you as a user on the services.</p>
            

            
            <p>Donors
            have the choice of opening a user account on the website or not, as it is
            possible to make a donation without being a registered user. We, however,
            advise donors who use the website and services to make repeated donations to
            open user accounts in order to allow for ease when making their donations. This
            is because the registration process will make it easy for the website to
            remember you. This way, you do not have to input your information every time
            that you use the services to make payment to a receiving organization.</p>
            

            
            <p>All
            users, whether receiving organizations or givers hereby represent that all the
            information that they provide to Jummah Connect are at all times truthful,
            complete, and not intentionally deceptive. You also accept that you will
            regularly update your account information as it changes. You also accept that
            you are fully and solely responsible for all activities that occur under your Jummah
            Connect account, and must protect your password at all times.</p>
            

            
            <p>You are
            to immediately bring it to our notice should you suspect that a breach of your
            account by a third party has occurred.</p>
            

            
            <h3><strong>COPYRIGHT,
            INTELLECTUAL PROPERTY, AND PROPRIETARY RIGHTS</strong></h3>
            

            
            <p>All
            materials, which without limitation, include written materials, graphics,
            pictures, designs, icons, and video clips made available on and found on this
            website are copyrighted or trademarked or controlled or licensed by Jummah
            Connect, our affiliates or by their respective creators, except otherwise
            stated. All such materials are fully protected by United Kingdom laws as well
            as international copyright laws. </p>
            

            
            <p>The
            Jummah Connect name, this website, our logos, page headers, scripts, button
            icons, and service names that are included in or provided through the website
            are trademarks or trade dress of Jummah Connect in the United Kingdom and other
            countries.&nbsp;&nbsp;</p>
            

            
            <h3><strong>SPECIFIC
            TERMS FOR RECEIVING ORGANISATIONS</strong></h3>
            

            
            <p><strong>Term and
            membership:</strong> The term of this agreement shall begin from
            the date on which the Receiving organization is notified that it has been
            successfully registered to use the Jummah Connect Services. You understand that
            this determination shall be at our sole discretion. &nbsp;Membership shall be for a minimum period of 12
            months from the Commencement Date and shall renew automatically after the first
            12 months. This automatic renewal shall continue until this agreement is
            terminated by written notice by either party to the other.</p>
            

            
            <p><strong>Licenses:</strong>
            It is your responsibility as a receiving organization to maintain the necessary
            authority, permit, license, consent, approval, and registration to receive
            funds from donors in compliance with applicable local laws. We may require you,
            but are not obligated, to provide us with appropriate documentation that
            authorizes and permits you to so receive funds.</p>
            

            
            <p><strong>Fees/subscriptions:</strong> The Jummah Connect service is a paid service, and you will be required to make payment for your first month of subscription at the point of registration. This subscription is automatically deductible on a monthly basis from the original method of payment you used during your registration and is payable before the service is rendered. <strong>NOTE: During Covid-19 lockdown March 2020, this platform will be completely free to use.</strong></p>
            

            
            <p><strong>Payment
            terms:</strong>&nbsp; The
            subscription fee shall be payable via bank transfer, as a lump-sum payment,
            unless Jummah Connect has given express permission otherwise. We may suspend
            your use of the Jummah Connect Services at any time should collection of the
            amount due not be successful for whatsoever reason or if you do not pay any
            other amount due to Jummah Connect under this agreement for any other reason.
            In this situation, we will only grant you access to the Jummah Connect Services
            upon receipt of any outstanding subscription fees.</p>
            

            
            <p><strong>Cancellations:</strong>
            Subscriptions will be automatically renewed, except you cancel your
            subscription with us/terminate this agreement. If you do not want to renew your
            subscription, you must inform us in a written notice at least seven days before
            the renewal of your current subscription.</p>
            

            
            <p><strong>Termination:</strong>
            Where either of the party is no longer interested in continuing their
            relationship, such party is required to send a notice of cancellation no later
            than six months prior to the Ramadan of that year. Your notice to Jummah
            Connect shall be deemed to have been received within five (5) business days if
            posted and within twenty-four (24) hours if emailed. </p>
            

            
            <p>Each
            party has the right to terminate the use (and provision) of the Jummah Connect
            Services with immediate effect by providing a written notice, and at any time
            after the second party commits an act of default such as being wound up or a
            liquidator or examiner is appointed; where an administration order is made in
            relation to the party or a receiver is appointed, or an encumbrancer takes
            possession of the party's assets; where the party makes an arrangement or
            composition with its creditors generally; where the party ceases or threatens
            to cease to carry on its business; or in the event that the Receiving
            organization materially breaches any of these Terms and Conditions. </p>
            

            
            <p><strong>Proper
            information:</strong> You are to immediately provide any
            information reasonably requested by Jummah Connect in order for us to be able
            to provide you with our services.</p>
            

            
            <p><strong>Withdrawal
            of funds:</strong> All registered organizations will be required
            to open a Stripe account for the purpose of receiving their donated funds. You,
            however, understand and accept that your first (initial) withdrawal of funds may
            take between 7 to 14 days before it is received by you. Subsequent withdrawals
            take 3 days.</p>
            

            
            <p><strong>Violation
            of the law:</strong> The receiving organization will comply with
            all applicable laws and regulations in its use of Jummah Connect Services.</p>
            

            
            <p>The receiving
            organization will ensure that all content (including text and images) supplied
            by it to Jummah Connect for display on the Jummah Connect Services will not breach
            any law or regulation; be defamatory, misleading, or obscene; and/or violate
            any intellectual property rights of a third party. </p>
            

            
            <p>The Receiving
            organization will inform Jummah Connect immediately if, for any reason, it
            ceases operations; it is found to be involved in any corrupt or immoral
            practices; is subject to any governmental investigation which could result in
            the suspension or withdrawal of its necessary authority, consent, permit,
            license, approval and/or registration; or if it ceases to have the necessary
            authority, consent, approval, permit, license, and/or registration for it to
            operate in accordance with applicable local laws.</p>
            

            
            <p><strong>Your
            representations:</strong> You hereby represent that these
            terms and conditions have been accepted and agreed to by a sufficient number of
            your duly authorized representatives; and that the individual signing this
            agreement on behalf of the organization has the approval of the organization to
            do so, and has the power/authority to bind the organization to the duties and
            obligations of these terms and conditions.</p>
            

            
            <p><strong>Intellectual
            Property Rights and Licence for receiving organizations:</strong>
            You hereby grant to Jummah Connect, its affiliates and its partners a
            nonexclusive, worldwide, and royalty-free license to use your name and logo to
            perform and promote Jummah Connect Services. This license shall terminate
            automatically on valid termination of your membership.</p>
            

            
            <p><strong>Confidential
            Information:</strong> During the term of the Receiving
            organization's membership and for seven (7) years thereafter, the parties shall
            treat the other party’s information acquired as a result of the use of Jummah
            Connect's Services (which is not in the public domain) as strictly confidential.
            None of the parties shall use or reveal to any third party this confidential
            information belonging to the other party without written consent, except where the
            party is constrained to do so by applicable local law or governmental body.
            This provision shall survive the termination of this agreement.</p>
            

            
            <p><strong>Insurance:</strong>
            Each party is required to always maintain adequate insurances as required by
            applicable local laws or as are standard for the parties to an agreement of this
            nature.</p>
            

            
            <h3><strong>SPECIFIC
            TERMS FOR DONORS/GIVERS</strong></h3>
            

            
            <p><strong>Fees:</strong>
            The Jummah Connect service is a free service for donors to use.</p>
            

            
            <p><strong>Making
            donations:</strong> You must click on the logo/hyperlink of
            your intended receiving organization on our website, and input the amount you
            are willing to donate, as well as the specific type of donation you are making.
            We will ask you for your billing details in order to facilitate your donations.
            You have the choice of making a small donation to the Jummah Connect services
            as well. You, however, understand that this is subject to your choice. </p>
            

            
            <p><strong>Payment processing:</strong> For Jummah Connect, Jummah Connect will process your donation via the payment method Stripe at the time of donating. NOTE: no donations will be refunded for any reason, subject to applicable local laws. Occasionally, the person who created the page to which you have donated does not pass identity verification or other necessary checks, or there is some other reason that we cannot transfer the funds to them. If we are unable to transfer the funds to the Organisation to which you donated, we will return your donation via the payment method from which you made the donation.&nbsp; If we are unable to return your donation via the payment method you used, your donation will be sent to another Organisation, subject to the deduction of applicable transaction and payment processing fees.</p>
            

            
            <p><strong>Gift
            Aid:</strong>&nbsp; Jummah
            Connect will offer all donors who are UK taxpayers the option of making
            donations in a manner that makes them eligible for Gift Aid reclaims. Jummah
            Connect will make available to receiving organization all data necessary to
            process the Gift Aid of their donors where the donor has given the appropriate
            permissions. </p>
            

            
            <p><strong>Violation
            of the law:</strong> We ask that you comply with all applicable
            laws and regulations in your use of Jummah Connect Services.</p>
            

            
            <h3><strong>PROHIBITED
            USES</strong></h3>
            

            
            <p>You
            may not use Jummah Connect’s services for any unlawful or illegal purpose; and
            you are not to break any laws in your geographic location/jurisdiction in the
            course of your use of the Jummah Connect website.</p>
            

            
            <p>You
            must not delete or remove any copyright, trademark or any other proprietary
            notice from any portion of our website; and are prohibited from linking to,
            mirroring, or framing any part of our website and services. </p>
            

            
            <p>You
            must not communicate or endeavour to communicate any worms, Trojans or viruses
            or any code of a damaging nature over and through the Jummah Connect network. </p>
            

            
            <p>You
            are expressly prohibited from copying or downloading any account information
            for the use of another merchant or website; and from reproducing, duplicating,
            copying, selling, or otherwise exploiting this website for any money-making
            reasons without our prior written consent.</p>
            

            
            <p>You
            understand and accept that your content (excluding your credit card
            information), may be transferred unencrypted over several networks; and may be
            altered or modified to conform to the technical necessities of connecting
            devices or networks.</p>
            

            
            <p>You
            are not to in any way attempt to gain unapproved access to our website, Jummah
            Connect’s host server, or any other server, computer or database connected to
            our website.</p>
            

            
            <p>You
            must not try to get/gain unauthorised access to or debilitate any segment of
            our website and/or its related systems.</p>
            

            
            <p>You
            may not in any way use data mining robots or other similar data extraction tools
            on the Jummah Connect website. </p>
            

            
            <p>You
            may not use information gathered from our website to contact organisations or
            people for the purpose of marketing your or other persons’ business.</p>
            

            
            <p>You
            are not to make, transmit or store electronic copies of any materials that are
            protected by copyright without the owner’s permission; interfere or disrupt
            websites or networks connected to the website; and/or gain unauthorised entry
            to other computer systems.</p>
            

            
            <p>You
            are prohibited from disseminating any unlawful, harassing, libellous, abusive,
            threatening, harmful, vulgar, obscene, or otherwise objectionable material.</p>
            

            
            <p>You
            may not employ any framing techniques to encircle any logo, copyright,
            trademark, or other proprietary information of the website or of Jummah Connect
            and its affiliates without written consent; and are not to disrupt any other
            person's use of the website.</p>
            

            
            <p>You
            may not disperse or spread material that encourages criminal conduct or that
            results in civil liability.</p>
            

            
            <p>Every
            user is solely responsible for his/her activities and conduct on the website,
            including but not limited to all text, usernames and passwords, data, graphics,
            images, photographs, video, audio, and links uploaded, displayed, and posted on
            the website.</p>
            

            
            <p>If
            we discover you to be in the breach of any of these terms, we will
            automatically terminate the license and permission granted you by Jummah
            Connect to use this website. </p>
            

            
            <h3><strong>DISCLAIMERS
            AND LIMITATION OF LIABILITY</strong></h3>
            

            
            <p>The Jummah
            Connect services are provided on an "as is" and "as
            available" basis. You, therefore, accept that your use of the Jummah
            Connect services is at your sole risk. Jummah Connect does not represent that
            its services will be continuous, uninterrupted, error, or virus free or secure.
            You accept that the operation and delivery of Jummah Connect services may be
            interfered with by numerous factors outside of our control. On that basis,
            except as expressly set out in these terms and conditions, Jummah Connect does
            not enter into conditions, representation, or other terms in relation to Jummah
            Connect services, and they are excluded to the total degree allowable by law.</p>
            

            
            <p>We shall
            in no event be liable for any loss or damage which any user may claim to have
            suffered by reason of its access to or use of/inability to use the Jummah
            Connect services, including without limitation the loss of profits, loss of
            sales, loss of donations, loss of revenue, loss of goodwill, loss of gift aid, loss
            of any software or data, loss of bargain/opportunity, loss of use of computer
            software, equipment or data, loss of productive time, exemplary or special
            damages, or for any indirect, incidental consequential or special loss, however
            arising.</p>
            

            
            <p>We may
            change the format and content of the Jummah Connect services from time to time.
            It is your responsibility to refresh your browser each time you visit the
            website to ensure that you download the current version of the website,
            including the latest version of these terms and conditions.</p>
            

            
            <p>Nothing
            in these terms and conditions is intentioned to exclude or limit Jummah Connect’
            or the user’s liability for death or personal injury resulting from its
            negligence, fraud, under any indemnity contained in this agreement, or any
            other liability which may not by applicable law be excluded or limited.</p>
            

            
            <p>The total
            aggregate liability of Jummah Connect arising in connection with any user’s
            access to and use of the Jummah Connect services and inability to access or use
            the Jummah Connect services shall be limited to the fees paid by the user under
            these terms and conditions during the preceding 12 months period ending on the
            date the circumstances giving rise to the liability arose.</p>
            

            
            <p>Where
            the jurisdiction does not allow for the limitation of liability as put forth
            above, this limitation of liability shall not apply to you.</p>
            

            
            <h3><strong>THIRD-PARTY
            RIGHTS</strong></h3>
            

            
            <p>This
            agreement makes no provisions for the rights of a third party who is not a
            party to these original terms and conditions.</p>
            

            
            <h3><strong>DISPUTE
            RESOLUTION</strong></h3>
            

            
            <p>If a
            dispute arises under this agreement or concerning its subject matter, both
            parties are required to give the other party a written notice requesting a
            meeting to seek to resolve the dispute. Both parties must meet within five (5) business
            days of the notice and attempt to resolve the dispute in good faith. </p>
            

            
            <p>In the
            absence of this, either party may pursue its rights at law.</p>
            

            
            <h3><strong>FULL
            AGREEMENT</strong></h3>
            

            
            <p>You
            understand and accept that this agreement represents the full agreement between
            you and Jummah Connect to the exclusion of any other document.</p>
            

            
            <h3><strong>ENFORCEABILITY</strong></h3>
            

            
            <p>If any
            term/provision of this agreement is held by any competent United Kingdom court
            or authority to be invalid in part or fully, the validity of the other
            provisions of this agreement and the remainder of the affected term shall be
            unaffected and shall remain effective.</p>
            

            
            <h3><strong>INDEMNITY</strong></h3>
            

            
            <p>You
            hereby agree to defend, hold blameless and indemnify Jummah Connect, its
            affiliates and subsidiaries, their officers, executives, managers, and
            representatives from any claims, compensations, liabilities, damages, and
            costs, such as but not restricted to, reasonable legal and accounting costs,
            related in any way with your use of the Jummah Connect website and services or
            related to your breach of these terms.</p>
            

            
            <h3><strong>GOVERNING
            LAW/JURISDICTION</strong></h3>
            

            
            <p>These
            terms of service are construed under and thus governed by the laws of the
            United Kingdom in all respects and regards. </p>
            
        </div>
    )
}

export default TermsAndConditions;