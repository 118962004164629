export const loadSelected = () => {
    try {
      const serializedState = localStorage.getItem('selected');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  }; 

export const saveSelected = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('selected', serializedState);
    } catch {
        // ignore write errors
    }
};