import React from 'react';
import './checkout.css';
import CheckoutForm  from '../../components/checkoutForm/checkoutForm';

function Checkout() {
window.dataLayer.push({
  'event': 'Pageview',
  'pagePath': '/checkout',
  'pageTitle': 'Checkout' //some arbitrary name for the page/state
});
      return (
        <div id="checkout-container">
          <h1 id="checkout-h1">Checkout</h1>
          <CheckoutForm />
        </div>
      );
}

export default Checkout;