import React, {useState} from 'react';
import './recurringDonations.css';
import {Redirect} from 'react-router-dom';
import Popup from 'reactjs-popup';
import cancelSubscription from './cancelSubscription';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux'; 

function RecurringDonations(props) {

    // Get LoggedInEmail from Redux State
    const loggedInEmailVar = useSelector(state => state.loggedInEmail);

    const loggedInLocal = localStorage.getItem('emailForSignIn');
    const loggedIn = localStorage.getItem('loggedIn');
    // Get Subscription ID from URL
    const url  = props.location.pathname;
    const subscriptionID = url.substring(23);
    const [stripeSubscriptionID, setStripeSubscriptionID] = useState();
    const [subscriptionStatus, setSubscriptionStatus] = useState();
    const [cancelDate, setCancelledDate] = useState();
    const [userMatch, setUserMatch] = useState();

    const getDonations = async (subscriptionID) => {
        
        await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/getSubscriptions', {
            method : 'POST',
            headers : {
                'Content-type': 'application/json'
            },
            body : JSON.stringify({ subscriptionID
            })
        })
        .then(response => response.text()) 
        .then((dataStr) => {
            let data = JSON.parse(dataStr);
            // Loop Through Donations
            var donations = [];
            var totalDonation = 0;
            var totalFees = 0;
            var totalSupport = 0;
            var frequency = data[0][0].frequency.charAt(0).toUpperCase()+data[0][0].frequency.slice(1);
            var masjidName = data[1][0].masjid_name;
            setStripeSubscriptionID(data[2][0].stripe_subscription_ID);
            var cancelledDate = new Date(data[2][0].cancel_date);
            var cancelledYear = cancelledDate.getFullYear();
            var cancelledMonth = cancelledDate.getMonth()+1;
            var cancelledDate = cancelledDate.getDate();
            var subscriptionEmail = data[2][0].user_ID;
            if (subscriptionEmail === loggedInLocal) {
                setUserMatch(true);
                    
                if (cancelledDate < 10) {
                    cancelledDate = '0' + cancelledDate;
                    }
                if (cancelledMonth < 10) {
                    cancelledMonth = '0' + cancelledMonth;
                }
                setCancelledDate( cancelledDate+'-' + cancelledMonth + '-'+cancelledYear);
                // Get Currecny and convert to icon
                var currency = data[3][0].currency;
                if (currency === 'GBP'){
                    currency = '£'
                } else if (currency === 'USD'){
                    currency = '$'
                } else if (currency === 'EUR'){
                    currency = '€'
                }
                setSubscriptionStatus(data[2][0].status);
                for (var i = 0; i < data[0].length; i++) {
                    // Convert Date   
                    var date = new Date(data[0][i].date);
                    var year = date.getFullYear();
                    var month = date.getMonth()+1;
                    var dt = date.getDate();

                    if (dt < 10) {
                    dt = '0' + dt;
                    }
                    if (month < 10) {
                    month = '0' + month;
                    }
                    var total = data[0][i].total_amount + data[0][i].support_amount + data[0][i].fees;
                    const results = 
                    `<div class="order-containers">
                        <p class="subscription-number-ribbon donation">Donation #<span class="ribbon-content donation">${data[0][i].order_ID}</span></p>
                        <p>Total Amount - <span>${currency+total.toFixed(2)} (inc fees)</span></p>
                        <p>Date - <span>${dt+'-' + month + '-'+year}</span></p>
                    </div>`
                    ;
                    donations.push(results);
                    totalDonation += data[0][i].total_amount;
                    totalFees += data[0][i].fees;
                    totalSupport += data[0][i].support_amount;
                }
                document.getElementById('account-donations').innerHTML = donations.join("");
                document.getElementById('summary').innerHTML = `<h2>${frequency} donation to ${masjidName}</h2><div class="donations-summary"><p>Total Donated - ${currency+totalDonation.toFixed(2)}</p><p>Fees Paid - ${currency+totalFees.toFixed(2)}</p><p>Jummah Connect Support - ${currency+totalSupport.toFixed(2)}</p></div>`;
            }
            else {
                setUserMatch(false);
                document.getElementById('summary').innerHTML = `<h2>It looks like you landed here by mistake!</h2><a href="/myaccount" class="jc-button">Back to My Account</a>`;
            }
        })
    
    }
    getDonations(subscriptionID);

    const [showProgress, setShowProgress] = useState(false);
    const SubmittedForm = () => {
        return (
        <>
            { showProgress ? <CircularProgress className="checkoutsubmit" color="white"/> : null }
        </>
        )
    }

    const CancelButton = () => (
    <Popup
        trigger={<button className="button jc-button cancel-button"> Cancel Future Donations </button>}
        modal
        nested
    >
        {close => (
        <div className="modal">
            <button className="close" onClick={close}>
            &times;
            </button>
            <div className="header"> Cancel recurring donation? </div>
            <div className="content">
            {' '}
            <h4>Are you sure you want to cancel this donation?</h4>
            <p>The masjid still needs your support and you will be dearly missed.</p>
            </div>
            <div className="actions">
            <button className="button jc-button cancel-button" onClick={() => {cancelSubscription(stripeSubscriptionID,loggedInLocal);setShowProgress(!showProgress)}}>I'm sure I want to cancel <SubmittedForm/></button>
            </div>
        </div>
        )}
    </Popup>
    );
    return (
        <div id="my-donations">
            {loggedIn ? "" : <Redirect to="/login"/>}
            <div id="summary"></div>
            <div id="account-donations"></div>
            {userMatch === false ? '' : 
                (subscriptionStatus==='Cancelled' ? <p>Date Cancelled - {cancelDate}</p> : <CancelButton/>)
            }
        </div>
    )
}

export default RecurringDonations;