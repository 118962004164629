import React from 'react';
import './nav.css';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import { withRouter } from 'react-router-dom';

function Nav() {
    const loggedInLocal = localStorage.getItem('loggedIn');
    const useStyles = makeStyles((theme) => ({
      list: {
        width: 250,
      },
      fullList: {
        width: 'auto',
      },
      root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        border: 'solid #166BB5 0.5px',
      },
      button: {
        marginLeft: theme.spacing(1),
        flex: 1,
        typography: {
            fontSize: 10,
        },
      },
      iconButton: {
        padding: 5,
        width:15,
      },
      divider: {
        height: 28,
        margin: 4,
      },
      searchmobile: {
        color: 'rgba(0, 0, 0, 0.54)',
        marginRight: 20
      },
      login: {
        color: 'rgba(0, 0, 0, 0.54)',
      }
    }));
    const classes = useStyles();
    
    const Search = (props) => {
        const { location } = props;
        if (location.pathname.match(/masjid/)){
          return null;
        }
      
        return (
            <li>    
                <Paper component="form" id="nav-search-container"  className={classes.root}>
                <Link id="nav-search" className={classes.button} to={'/masjid'}>Search Masjids</Link>
                <SearchIcon className={classes.iconButton} aria-label="search"/>
                </Paper>
            </li>
        )
      }
      
    const ComponentThatHides = withRouter(Search);
    const SearchMobile = (props) => {
        const { location } = props;
        if (location.pathname.match(/masjid/)){
          return null;
        }
      
        return (
            <Link id="nav-search" className={classes.searchmobile} to={'/masjid'}><SearchIcon aria-label="search"/></Link>
        )
      }
      
    const ComponentThatHidesMobile = withRouter(SearchMobile);
    return (
        <nav>
            <Link to='/' className="logo"><img src={window.location.origin + '/jummah-connect-logo.png'} width="150px" alt="Jummah Connect Logo"/></Link>
            <div id="mobile-nav">
              <ComponentThatHidesMobile />
              <Link to='/myaccount' className="login-link"><PersonIcon className={classes.login} aria-label="login"/><span>My Account</span></Link>
            </div>
            <div id="desktop-nav">
                <ul className='nav-links'>
                    {loggedInLocal ? "" : <Link to='/login'><li>Log In</li></Link>}
                    {loggedInLocal ? <Link to='/myaccount'><li>My Account</li></Link> : ""}
                    {loggedInLocal ? <Link to='/logout'><li>Log Out</li></Link> : ""}
                    <ComponentThatHides />
                </ul>
            </div>
        </nav>
    )

}

export default Nav;