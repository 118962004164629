import React from 'react';
import './share.css';
import Zoom from '@material-ui/core/Zoom';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TwitterIcon from '@material-ui/icons/Twitter';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ShareImage from './share-image.png';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  tick: {
    width: 75,
    height: 75,
    color:'#00c100',
    margin: 'auto',
  },
  icons: {
      color:'#166bb5',
      width: 40,
      height: 40,
  }
}));

function Share() {
    window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': '/share',
        'pageTitle': 'Share' //some arbitrary name for the page/state
    });

    function shareButtonClick (){
        return(
            window.dataLayer.push({
                'event': 'ShareButtonClick',
                'pagePath': '/share',
                'pageTitle': 'Share' //some arbitrary name for the page/state
            })
        )
    }
    const classes = useStyles();
    const checked = true;
    let selectedMasjid = localStorage.getItem('selected');
    selectedMasjid = JSON.parse(selectedMasjid);
    var masjidName = selectedMasjid.selectedMasjidName;
    var masjidURL = selectedMasjid.selectedMasjidID;
    const Whatsapp = () => {
        var whatsappURL = "whatsapp://send?text=I just donated to "+ masjidName +" with Jummah Connect! You can donate too from this link - https://jummahconnect.com/masjid/"+masjidURL+""
        return (
        <li><a id="Whatsapp" className="share-button" target="new" href={whatsappURL} data-action="share/whatsapp/share"><WhatsAppIcon className={classes.icons}/></a></li>
        )
    }
    const Twitter = () => {
        var twitterURL = "http://twitter.com/share?text=I just donated to "+ masjidName +" with Jummah Connect! You can donate too from this link - https://jummahconnect.com/masjid/"+masjidURL+"&hashtags=jummahconnect"
        return (
        <li><a id="Twitter" className="share-button" target="new" href={twitterURL} data-action="share/twitter/share"><TwitterIcon className={classes.icons}/></a></li>
        )
    }
    const Facebook = () => {
        var facebookURL = "https://www.facebook.com/sharer/sharer.php?u=https://jummahconnect.com/masjid/"+masjidURL+""
        return (
        <li><a id="Facebook" className="share-button" target="new" href={facebookURL} data-action="share/facebook/share"><FacebookIcon className={classes.icons}/></a></li>
        )
    }
    const Email = () => {
        var emailURL = "mailto:someone@gmail.com?subject=Join%20me%20in%20donating%20to%20"+ masjidName +"!&body=I%20just%20donated%20to%20"+ masjidName +"%20with%20Jummah%20Connect!%20You%20can%20donate%20too%20from%20this%20link%20-%20https://jummahconnect.com/masjid/"+masjidURL+""
        return (
        <li><a id="Email" className="share-button" target="new" href={emailURL} data-action="share/email/share"><MailOutlineIcon className={classes.icons}/></a></li>
        )
    }
    var copyURL = "I just donated to "+ masjidName +" with Jummah Connect! You can donate too from this link - https://jummahconnect.com/masjid/"+masjidURL+""
    
    function copy() {
        navigator.clipboard.writeText(copyURL);
        document.getElementById("Copy").innerHTML='<span>Copied!</span>'
    }
    return (
        <div id="share">

            <div>
                <div className={classes.container}>
                    <Zoom in={checked} style={{ transitionDelay: checked ? '2000ms' : '0ms' }}>
                        <CheckCircleIcon  className={classes.tick}/>
                    </Zoom>
                </div>
            </div>
            <p>Your donation was successful!</p>
            <h3>Share to earn extra rewards with Allah!</h3>
            <p className="small-print">Your share will multiply your rewards if someone donates from your link in'sha'Allah.</p>
            <div id="shareList">
                <ul>
                    <Whatsapp />
                    <Twitter />
                    <Facebook />
                    <Email />
                    <li><a id="Copy" className="share-button" onClick={copy} href="#" data-action="share/copy/share"><FileCopyIcon className={classes.icons}/></a></li>
                </ul>
            </div>
            <img src={ShareImage} alt="Thanks" width="200px"/>
        </div>
    )
}

export default Share;