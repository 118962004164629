import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

function FooterComp() {
    
    return (
        <div className="footer-container">
            <div className="footer-wrapper">
                <div className="footer-links">
                    <a href={window.location.origin +'/contact-us'}>Contact Us</a>
                    <a href={window.location.origin +'/terms-and-conditions'}>Terms and Conditions</a>
                    <a href={window.location.origin +'/privacy-policy'}>Privacy Policy</a>
                </div>
                <p>Jummah Connect is a UK registered company 12538565. Registered Address: Jummah Connect, 15 Queen Square, Leeds, LS2 8AJ.</p>
                <div className="social">
                    <a href="https://www.facebook.com/jummahconnect" target="new"><FacebookIcon className="footer-icons"/></a>
                    <a href="https://twitter.com/jummahconnect" target="new"><TwitterIcon className="footer-icons"/></a>
                    <a href="https://www.instagram.com/jummahconnect" target="new"><InstagramIcon className="footer-icons"/></a>                                
                </div>
            </div>
        </div>
    )

}

export default FooterComp;