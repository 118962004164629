import React from 'react';
import './static-pages.css';

function PrivacyPolicy() {
    window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': '/privacy-policy',
        'pageTitle': 'Privacy Policy' //some arbitrary name for the page/state
    });
    return (
        <div id="privacy-policy-container">
            <h1>Privacy Policy</h1>            
            <p>We
            understand the importance of maintaining user privacy and confidentiality and
            remain ever committed to helping our users feel safe on our website. To
            understand how we do this, we have fashioned this privacy policy to describe how
            we use your private information that we gain access to when you use our
            website.</p>

            <p>Your use
            of the Jummah Connect website and its services means that you agree to the way
            we use, share, disclose, store, and safeguard your information when you use our
            services. Your use also means that you consent to be legally bound by the terms
            of this Privacy Policy. If you are not in complete agreement with our privacy
            notice/policy, you must stop using the service and exit this website
            immediately. </p>

            <p>We may
            sometimes review this Privacy Policy and will always post the last updated date
            on the top portion of this page.</p>

            <p>Your
            continued use of the Jummah Connect website after such changes are made
            indicates your continued approval of our privacy policy. </p>

            <h3><strong>INFORMATION
            COLLECTION</strong></h3>

            <p>It is
            our policy to only collect the personal information needed to receive/complete
            a donation and meet our obligations to you. This may include </p>

            <p><strong>Personally
            identifiable info:</strong> This includes your full name,
            e-mail address, physical address, business name and address (if applicable),
            and phone number. The common thing about these kinds of information is that
            they are voluntarily provided by you.</p>

            <p><strong>Billing/banking
            information:</strong> As ours is a payment platform, we will
            collect billing information from donors in order to facilitate their donations.
            For one-time donors, we may not save your billing details. For donors who have
            registered on the website, however, we may save your billing details in order
            to ensure the ease of payments in subsequent donations. We will also collect
            banking and Stripe information from receiving organizations in order to facilitate
            their receipt of donations.</p>

            <p><strong>Other
            important information:</strong> As per your specific use of our
            service, we may require other important information from you. For example, we
            may require donors who are using our Gift Aid button to provide us with their
            tax IDs. We may also ask receiving organizations to provide us with proofs of
            their licenses/approvals to operate in the manner in which they do.</p>

            <p><strong>Information
            that we collect via cookies and other technologies:</strong>
            We may use certain technologies to obtain information from your computer about
            what actions you take when you visit our website. The information we collect
            this way is not voluntarily provided by you but does not personally identify
            you. We use aggregated data from customer usage to optimize our experience.</p>

            <h3><strong>USING
            YOUR INFORMATION</strong></h3>

            <p>We will
            only use the information that we collect about you, whether voluntarily-given
            or otherwise in the following ways:</p>

            <p>We will
            use your information to deliver our services to you. This means that we will
            use this to deliver donations to receiving organizations and to receive
            donations on their behalf from givers.</p>

            <p>If you
            have agreed to be so informed, we may use your information to inform you about
            services and/or events that may interest you.</p>

            <p>We may
            use your information to provide administration and IT services, to ensure
            network security and to grow our business.</p>

            <p>We may
            use your information to continually appraise, and constantly improve our
            website and its services; to bring to your immediate notice important changes
            to our website and services; to customize your experience; to troubleshoot
            problems; to resolve disputes; and to protect ourselves and other users against
            error, fraud and/or other criminal activities.</p>

            <p>You understand
            that you do not have to provide all of the information we request, but if you
            do not provide certain information, we will only be able to provide limited
            services to you.</p>

            <h3><strong>REVEALING
            YOUR INFORMATION</strong></h3>

            <p>We may
            reveal your private information, including full name and contact address to any
            pre-approved service providers that deploy some services on our behalf. These
            are:</p>

            <p>Third parties
            whom we allow to place ads on our websites may, in some cases, use tracking
            technologies like cookies to gather information about users who view their ads.
            If you view their ads, your general information may be collected by them, but
            be assured that our website does not disclose any personal detail to these
            third parties. </p>

            <p>We may
            choose to release details of your personal information to appropriate legal
            authorities and/ or government officials if mandated under law to do so in
            reply to a subpoena, an investigative demand, or a court order. </p>

            <p>Following
            your permission, we may also share your information with third parties with
            whom you choose to let us share information. </p>

            <p>For
            donors, we may use your information to allow organizations to have visibility
            of the donations made to them so they can track the total donations (in an
            anonymous form, unless you provide appropriate permissions). Where you provide
            appropriate permissions, we may also use your information to allow the
            receiving organization to provide you with more details about their activities;
            and/or contact you to pass on a thank you message. </p>

            <p>We may
            reveal your information to the following categories of recipients; employees of
            Jummah Connect; and banks and payment providers - to authorize and complete
            payment transactions.</p>

            <p>We do
            not sell or offer for sale, transfer, or give any of our users' private
            information to any third party under any circumstance. Specifically, we do not
            send out marketing communications to you through any medium without your
            explicit consent by opting in via a checkbox.</p>

            <h3><strong>RECIPIENTS
            OUTSIDE OF THE EU</strong></h3>

            <p>Some of
            the recipients of your information may be located outside of the EU. In this
            case, we will only disclose your data to recipients in countries that provide
            an adequate level of data protection according to a decision by the European
            Commission; or to recipients in the US who are certified according to the EU-US
            Privacy Shield Framework approved by the European Commission; and only if we
            have taken measures to ensure that the recipients provide an adequate level of
            data protection. For example, in such case, we may use standard contractual
            data protection clauses approved by the European Commission.</p>

            <h3><strong>OUR USE
            OF COOKIES/GOOGLE ANALYTICS </strong></h3>

            <p>Our use
            of cookies helps us to automatically gather information about your online
            activities on our website, such as pages viewed by you, the links opened by
            you, and the searches you carried out on our website. You may, however, deny
            the use of cookies through your web browser, but you do this understanding that
            this may not allow you to enjoy the unique services that we offer.</p>

            <p>We also
            use Google Analytics, Google Tag Manager, and Facebook Pixel so that we can
            better understand our customers, and use the business insights gathered through
            our use of the Analytics to improve our content and services delivery.</p>

            <h3><strong>GDPR
            COMPLIANT POLICY</strong></h3>

            <p>Controlling
            your private information/data: You have all rights to correct and update your
            private information in our records if you believe that it is erroneous or if it
            has been deleted. You may also place limitations on the manner in which we
            process your information. You have all rights to instruct Jummah Connect not to
            use or process your private information/data in certain circumstances, i.e.,
            for advertising and marketing purposes. You have all the right to withdraw your
            agreement, where the processing of your private information is based on your
            consent. This is, however, subject to legal or contractual restrictions. </p>

            <p>How you
            can access your information: We want to ensure that your information that we
            hold is always accurate and up to date.&nbsp;
            Whenever there are any material changes to your private
            information/data, please sign in to your account (if any) and edit your profile
            to reflect the changes, so that we can update our records.</p>

            <p>Storage
            time: You understand and accept that we will maintain the information collected
            from you for commercially reasonable periods of time or as per specific
            policies, unless where proscribed by law.&nbsp;
            Contact details such as your name, phone number, and e-mail address
            provided to us by you are stored in our database, and it is our policy to
            delete such within a three-month period after you have notified us that you no
            longer wish to use our services.</p>

            <h3><strong>SECURITY
            </strong></h3>

            <p>To
            maintain your trust, we do all that we can to ensure the security and
            confidentiality of your information. To prevent illegal access, ensure
            information accuracy, and the proper use of information, we deploy practical
            measures to secure your information. You, however, agree and admit that there
            is no total assurance of information security on the internet. As such, we will
            not be answerable for the unauthorized access to your information that is
            beyond our control.</p>

            <h3><strong>CHANGES
            TO OUR PRIVACY POLICY </strong></h3>

            <p>We ask
            that you always visit this page to keep yourself updated as to our most current
            policy and practices. This is because our policies, including our privacy
            policy, are always changing, even as we try to deploy new services and
            implement new technology. Your continued use of the Jummah Connect website
            after changes are uploaded means that you fully agree to comply with our new
            privacy policy.</p>
        </div>
    )
}

export default PrivacyPolicy;