import React from 'react';
import './myaccount.css';
import {Redirect} from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LinearProgress from '@material-ui/core/LinearProgress';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function Logout() {
    var email = localStorage.getItem('emailForSignIn');
    const logoutUser = async () => {
        await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/myAccountLogout', {
            method : 'POST',
            headers : {
                'Content-type': 'application/json'
            },
            body : JSON.stringify({ email
            })
        })
    }
    logoutUser();
    localStorage.removeItem('emailForSignIn');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('UserType');
    localStorage.removeItem('EditPage');
    localStorage.removeItem('selected');
    localStorage.removeItem('masjidAbout');
    localStorage.removeItem('previousUpdate');
    cookies.remove("jclocro");
    const reload = () => {
        window.location.reload();   
        window.location.replace("https://jummahconnect.com");
    }
    setTimeout(reload, 5000);
    return (
        <div id="logout">
            <ExitToAppIcon className="logout-icon"/>
            <h1>You are now logged out!</h1>
            <p>Don't forget you can login any time to keep track of your donations.</p>
            <LinearProgress />
        </div>   
    )
}

export default Logout;