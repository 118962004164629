import React, {useState} from 'react';
import { withRouter } from "react-router-dom";
import './donationWidget.css';
import { selectedMasjidName, selectedMasjidID, selectedAmount, selectedFrequency, giftAidApplicable, masjidCurrency} from '../../reducers/actions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {saveSelected} from '../../localStorage';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';


function DonationWidget(props) {
  const { register, handleSubmit, errors } = useForm();

  var masjidID = {id:props.masjidID};
  masjidID = masjidID.id;
  var masjidName = {masjidName:props.masjidName};
  masjidName = masjidName.masjidName;
  var giftAid = {giftAid:props.giftAid};
  giftAid = giftAid.giftAid;
  var currency = {currency:props.currency};
  currency = currency.currency;

  const dispatch = useDispatch();

  const [amount, setAmount] = useState(5);
  const [frequency, setFrequency] = useState('weekly');

  const handleChangeAmount = (event) => {
    setAmount(event.target.value);
  }
  const handleChangeFrequency = (event) => {
    setFrequency(event.target.value);
  }

  // Get Currecny and convert to icon
  if (currency === 'GBP'){
      currency = '£'
  } else if (currency === 'USD'){
      currency = '$'
  } else if (currency === 'EUR'){
      currency = '€'
  }
  const handleSubmitForm = (event) => {
    // event.preventDefault();
    props.history.push('/checkout');
    dispatch(selectedMasjidID(masjidID));
    dispatch(selectedMasjidName(masjidName));
    dispatch(selectedAmount(amount));
    dispatch(selectedFrequency(frequency));
    dispatch(giftAidApplicable(giftAid));
    dispatch(masjidCurrency(currency));
    saveSelected({
      selectedMasjidName:masjidName, 
      selectedMasjidID:masjidID, 
      selectedAmount:amount, 
      selectedFrequency:frequency,
      giftAidApplicable:giftAid,
      masjidCurrency:currency
    });
  }

  return (
    <div id="donation-widget"> 
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div id="frequency-container">
          <p>Select how often you would like to donate and the amount.</p>
          <div id="frequency-select-container">
            <select value={frequency} onChange={handleChangeFrequency} type="select"  name="frequency" id="frequency">
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="onetime">One-Time</option>
            </select>
          </div>
          <input value={amount} onChange={handleChangeAmount} name="amount" type="number" id="amount" ref={register({ required: true })} ></input>
          <span id="amount-currency">{currency}</span>
        </div>
          {errors.amount && <span className="error-amount">Please enter an amount.</span>}
        <div className="donation-container">
          <button className="jc-button donation-button" type="submit" >Donate Now</button>
          <div className="secure-donation">
            <HttpsOutlinedIcon fontSize="small"/>
            <p> Secure Donation</p>
          </div>
        </div>
      </form>
    </div>
  );
}
export default withRouter(DonationWidget);