import React from 'react';
import './static-pages.css';

function PrivacyPolicy() {
    window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': '/contact-us',
        'pageTitle': 'Contact Us' //some arbitrary name for the page/state
    });
    return (
        <div id="contact-us-container">
            <h1>Get in touch!</h1>  
            <div class="contact-method">
                <h2>Email Us</h2>
                <p>support@jummahconnect.com</p>
            </div> 
            <div class="contact-method">
                <h2>Call Us</h2>
                <p>+44 0113 873 0246</p>
            </div>        
            <div class="contact-method">
                <h2>Write to Us</h2>
                <p>15 Queen Square, Leeds, West Yorkshire, United Kingdom, LS2 8AJ</p>
            </div>     
        </div>
    )
}

export default PrivacyPolicy;