import React, {useState ,useEffect} from 'react';
import './App.css';
import Nav from './components/nav/nav';
import MyAccount from './routes/myaccount/myaccount';
import Masjid from './routes/masjid/masjid';
import MasjidDetail from './routes/masjid/masjidDetail';
import Checkout from './routes/checkout/checkout';
import Share from './routes/share/share';
import Login from './routes/myaccount/login';
import Logout from './routes/myaccount/logout';
import Register from './routes/register/register';
import PrivacyPolicy from './routes/static-pages/privacy-policy';
import TermsAndConditions from './routes/static-pages/terms-and-conditions';
import ContactUs from './routes/static-pages/contact-us';
import MyDonations from './components/myAccount/myDonations';
import RecurringDonations from './components/myAccount/recurringDonations';
import MasjidDonations from './components/myAccount/masjidDonations';
import MasjidProfile from './components/myAccount/masjidProfile';
import MasjidStripe from './components/myAccount/masjidStripe';
import FooterComp from './components/footer/footer';
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {Helmet} from "react-helmet";
import { withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import TodayIcon from '@material-ui/icons/Today';
import HttpsIcon from '@material-ui/icons/Https';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

var stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY_STRIPE_LIVE);

function App() {
  window.dataLayer = window.dataLayer || [];

  const Home = () => {
    window.dataLayer.push({
      'event': 'Pageview',
      'pagePath': '/',
      'pageTitle': 'Jummah Connect' //some arbitrary name for the page/state
      });
    return (
    <div className="home-container">
      <div className="home-intro-container">
        <div className="home-intro-wrapper">
          <h1>Jummah Connect</h1>
          <div className="slogan-image-container">
              <p>Automate your donations to your local masjid every week so you never miss a Jummah donation again!</p>
          </div>
          <div>
            <Link to={'/masjid'} className='jc-button home-button'>Find Masjid</Link>
          </div>
        </div>
      </div>
      <div className="home-hiw-container">
          <h2>Donation Platform Made for Masjids</h2>
          <div className="home-hiw-items-container">
            <div className="hiw-item-container">
              <ThumbUpIcon className="hiw-item"/>
              <div>
                <h3>100% Donation Policy</h3>
                <p>Every last penny you donate, goes straight to the Masjid of your choice, we don't take a cut.</p>
              </div>
            </div>
            <div className="hiw-item-container">
              <AccountBalanceIcon className="hiw-item"/>
              <div>
                <h3>Secure</h3>
                <p>We use a secure and trusted payment system, to send funds to the Masjids accounts, not ours.</p>
              </div>
            </div>
            <div className="hiw-item-container">
              <AutorenewIcon className="hiw-item"/>
              <div>
                <h3>Automated</h3>
                <p>One transaction, and you'll never need to worry about making a donation on Jummah again.</p>
              </div>
            </div>
          </div>
          <div className="home-hiw-items-container">
            <div className="hiw-item-container">
              <SearchIcon className="hiw-item"/>
              <div>
                <h3>Search</h3>
                <p>Find the masjid you are looking for using the search function.</p>
              </div>
            </div>
            <div className="hiw-item-container">
              <TodayIcon className="hiw-item"/>
              <div>
                <h3>Select</h3>
                <p>Choose the frequency that you want to donate by, weekly, monthly or one-time.</p>
              </div>
            </div>
            <div className="hiw-item-container">
              <HttpsIcon className="hiw-item"/>
              <div>
                <h3>Submit</h3>
                <p>Make a secure payment directly to the masjids account.</p>
              </div>
            </div>
          </div>
      </div>
      <div className="home-masjids-container">
        <div className="home-masjids-wrapper">
          <h2>Choose From the Growing List of Registered Masjids</h2>
          <div className="home-masjids-items-container">
            <div className="masjid-item-container">
              <img src={window.location.origin + '/masjids-home/Cheadle-masjid.png'} alt="cheadle masjid" width="100px"/>
            </div>
            <div className="masjid-item-container">
              <img src={window.location.origin + '/masjids-home/al-emaan-centre.png'} alt="al emaan" width="100px"/>
            </div>
            <div className="masjid-item-container">
              <img src={window.location.origin + '/masjids-home/hounslow-muslim-centre.png'} alt="hounslow muslim centre" width="100px"/>
            </div>
            <div className="masjid-item-container">
              <img src={window.location.origin + '/masjids-home/milton-keynes.png'} alt="mliton keynes" width="100px"/>
            </div>
            <div className="masjid-item-container">
              <img src={window.location.origin + '/masjids-home/noorul-islam.png'} alt="noorul-islam" width="100px"/>
            </div>
            <div className="masjid-item-container">
              <img src={window.location.origin + '/masjids-home/muslim-welfare.png'} alt="muslim welfare" width="100px"/>
            </div>
          </div>
          <div className="register-home">
            <h3>Want to Register Your Masjid?</h3>
            <a href="/register" className="jc-button">Find out more</a>
          </div>
        </div>
      </div>
    </div>)
    }
  const NavShow = (props) => {
      const { location } = props;
      if (location.pathname.match(/checkout/)){
        return null;
      }
    
      return (
        <Nav />
      )
  }
  const ShowNav = withRouter(NavShow);
  const FooterShow = (props) => {
      const { location } = props;
      if (location.pathname.match(/checkout/)){
        return null;
      }
    
      return (
        <FooterComp />
      )
  }
  const ShowFooter = withRouter(FooterShow);
  return (
  <Elements stripe={stripePromise}>
    <Helmet>
    <title>Jummah Connect | Automated Weekly Donation to Masjids</title>
    <meta name="description" content="Jummah Connect is a platform that allows donors to make simple weekly donations and gives organisaitons the ability to recieve funds directly to their banks." />
    </Helmet>
    <Router>
      <div className="App">
        <header>
          <ShowNav />          
        </header>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/masjid" exact component={Masjid}   />
          <Route path="/masjid/:name" component={MasjidDetail}   />
          <Route path="/checkout" exact component={Checkout}   />
          <Route path="/share" exact component={Share}   />
          <Route path="/login" exact component={Login}   />
          <Route path="/logout" exact component={Logout}   />
          <Route path="/register" exact component={Register}   />
          <Route path="/myaccount" component={MyAccount} />
          <Route path="/mydonations" exact component={MyDonations} />
          <Route path="/mydonations/recurring/:name" component={RecurringDonations}   />
          <Route path="/masjiddonations" component={MasjidDonations} />
          <Route path="/masjidprofile" component={MasjidProfile} />
          <Route path="/masjidstripe" component={MasjidStripe} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-and-conditions" component={TermsAndConditions} />
          <Route path="/contact-us" component={ContactUs} />
        </Switch>
      </div>
      <ShowFooter />
    </Router>
  </Elements>
  );
}

export default App;