import React, {useState} from 'react';
import './masjidDonations.css';
import {Redirect} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import CsvDownload from 'react-json-to-csv';
import Divider from '@material-ui/core/Divider';

function MasjidDonations() {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(new Date());
    const [dataDownload, setDataDownload] = useState(null);
    const [uniqueUsers, setUniqueUsers] = useState(null);
    const [totalAmount, setTotalAmount] = useState(null);
    const [weeklyDonors, setWeeklyDonors] = useState(null);
    const [monthlyDonors, setMonthlyDonors] = useState(null);
    const [weeklyDonations, setWeeklyDonations] = useState(null);
    const [monthlyDonations, setMonthlyDonations] = useState(null);
    
    const PickDate = () => {
        return (
          <div id="date-picker-container">
              <div id="start-date-container">
                <span>Start Date</span>
                <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                />
            </div>
            <div id="end-date-container">
                <span>End Date</span>
                <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                />
            </div>
          </div>
        );
      };
    const loggedInLocal = localStorage.getItem('emailForSignIn');
    const loggedIn = localStorage.getItem('loggedIn');

    const getStats = async () => {
        await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/getMasjidDonations/stats', {
            method : 'POST',
            headers : {
                'Content-type': 'application/json'
            },
            body : JSON.stringify({ loggedInLocal
            })
        })
        .then(response => response.text()) 
        .then((dataStr) => {
            let data = JSON.parse(dataStr);
            // Count Unique Users
            var counts = {};
            for (var i = 0; i < data[1].length; i++) {
                counts[data[1][i].user_ID] = 1 + (counts[data[1][i].user_ID] || 0);
            }
            var totalUsers = Object.keys(counts).length;
            setUniqueUsers(totalUsers);

            // Total Amount Donated
            var amountDonated = 0;
            for (var i = 0; i < data[1].length; i++) {
                amountDonated += data[1][i].total_amount
            }                
            // Currency Conversion
            var currency = data[2][0].currency;
            if (currency === 'GBP'){
                currency = '£'
            } else if (currency === 'USD'){
                currency = '$'
            } else if (currency === 'EUR'){
                currency = '€'
            }
            amountDonated=  amountDonated.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2});
            setTotalAmount(currency+amountDonated);

            // Weekly Donors
            var totalWSubscribers = 0;
            for (var i = 0; i < data[3].length; i++) {
                totalWSubscribers ++;
            }          
            setWeeklyDonors(totalWSubscribers);
            var amountDonatedW = 0;
            for (var i = 0; i < data[3].length; i++) {
                amountDonatedW += data[3][i].total_amount
            }              
            amountDonatedW = amountDonatedW.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2});
            setWeeklyDonations(currency+amountDonatedW);

            // Monthly Donors
            var totalMSubscribers = 0;
            for (var i = 0; i < data[4].length; i++) {
                totalMSubscribers ++;
            }          
            setMonthlyDonors(totalMSubscribers);
            var amountDonatedM = 0;
            for (var i = 0; i < data[4].length; i++) {
                amountDonatedM += data[4][i].total_amount
            }              
            amountDonatedM = amountDonatedM.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2});
            setMonthlyDonations(currency+amountDonatedM);
        })
    };
    getStats();

    const getMasjidDonations = async () => {
        
        await fetch('https://3i36udtgze.execute-api.eu-west-2.amazonaws.com/latest/getMasjidDonations', {
            method : 'POST',
            headers : {
                'Content-type': 'application/json'
            },
            body : JSON.stringify({ loggedInLocal, startDate, endDate
            })
        })
        .then(response => response.text()) 
        .then((dataStr) => {
            let data = JSON.parse(dataStr);
            setDataDownload(data[1]);
            if (data[1].length === 0) {
                setDataDownload(null);
                document.getElementById("table-area").innerHTML = "<p>No data found, please amend the date range.</p>"
            } else {
                var tableRef = document.getElementById("donation-results").getElementsByTagName('tbody')[0];
                while(tableRef.rows.length > 0) {
                    tableRef.deleteRow(0);
                }
                // Currency Conversion
                var currency = data[2][0].currency;
                if (currency === 'GBP'){
                    currency = '£'
                } else if (currency === 'USD'){
                    currency = '$'
                } else if (currency === 'EUR'){
                    currency = '€'
                }
                for (var i = 0; i < data[1].length; i++) {
                    // Convert Date   
                    var date = new Date(data[1][i].date);
                    var year = date.getFullYear();
                    var month = date.getMonth()+1;
                    var dt = date.getDate();

                    if (dt < 10) {
                    dt = '0' + dt;
                    }
                    if (month < 10) {
                    month = '0' + month;
                    }
                    // Gift Aid from number to words
                    var giftAid;
                    if (data[1][i].giftAid === 1) {
                        giftAid = "❌"
                    } else {
                        giftAid = "✅"
                    }
                    
                    var newRow   = tableRef.insertRow();
                    var orderCell  = newRow.insertCell(0);
                    var orderText  = document.createTextNode(data[1][i].order_ID);
                    var dateCell  = newRow.insertCell(1);
                    var dateText  = document.createTextNode(dt+'-' + month + '-'+year);
                    var amountCell  = newRow.insertCell(2);
                    var nameText  = document.createTextNode(currency+data[1][i].total_amount.toFixed(2));
                    var nameCell  = newRow.insertCell(3);
                    var amountText  = document.createTextNode(data[1][i].first_name+ ' '+ data[1][i].last_name);
                    var frequencyCell  = newRow.insertCell(4);
                    var frequencyText  = document.createTextNode(data[1][i].frequency);
                    var giftAidCell  = newRow.insertCell(5);
                    var giftAidText  = document.createTextNode(giftAid);
                    orderCell.appendChild(orderText);
                    dateCell.appendChild(dateText);
                    nameCell.appendChild(nameText);
                    amountCell.appendChild(amountText);
                    frequencyCell.appendChild(frequencyText);
                    giftAidCell.appendChild(giftAidText);
                }
            }
        })
    
    }
    return (
        <div id="masjid-donations-container">
            {loggedIn ? "" : <Redirect to="/login"/>}
            <h2>Donations Summary</h2>
            <div id="overall-stats">
                <div><h3>Total Donated</h3><p>{totalAmount}</p></div>
                <div><h3>Weekly Donors</h3><p>{weeklyDonors}({weeklyDonations})</p></div>
                <div><h3>Monthly Donors</h3><p>{monthlyDonors}({monthlyDonations})</p></div>
                <div><h3>Unique Donors</h3><p>{uniqueUsers}</p></div>
            </div>
            <Divider className="masjid-donations-divider"/>
            <h2>Generate Report</h2>
            <PickDate/>
            {startDate === null ? "" : 
            <div>
                <button className="jc-button download-report" onClick={getMasjidDonations}>Update Report</button>
            </div>}
            {dataDownload === null ? "" : 
                <CsvDownload  className="jc-button download-report" data={dataDownload} />
            }
            <div id="table-area">
            {dataDownload === null ? "" : 
                <table id="donation-results">
                    <thead>
                        <tr>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Amount</th>
                        <th>Frequency</th>
                        <th>Gift Aid</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td></td></tr>
                    </tbody>
                </table>
            }
            </div>
        </div>
    )
}

export default MasjidDonations;