
export const loggedIn = () => {
    return {
        type: 'SIGN_IN'
    };
};
export const selectedMasjidID = (masjidId) => {
    return {
        type: 'SELECTEDMASJIDID',
        masjidID:masjidId
    };
};
export const selectedMasjidName = (masjidName) => {
    return {
        type: 'SELECTEDMASJIDNAME',
        masjidName:masjidName
    };
};
export const selectedAmount = (amount) => {
    return {
        type: 'SELECTEDAMOUNT',
        amount:amount
    };
};
export const selectedFrequency = (frequency) => {
    return {
        type: 'SELECTEDFREQUENCY',
        frequency:frequency
    };
};
export const selectedSupportAmount = (supportAmount) => {
    return {
        type: 'SELECTEDSUPPORTAMOUNT',
        supportAmount:supportAmount
    };
};
export const giftAidApplicable = (giftAid) => {
    return {
        type: 'GIFTAID',
        giftAidApplicable:giftAid
    };
};
export const masjidCurrency = (masjidCurrency) => {
    return {
        type: 'MASJIDCURRENCY',
        masjidCurrency:masjidCurrency
    };
};
export const loggedInEmail = (loggedInEmail) => {
    return {
        type: 'LOGGEDINEMAIL',
        loggedInEmail:loggedInEmail
    };
};
